
* {
    padding: 0;
    box-sizing: border-box;
}

body {
    &::-webkit-scrollbar {
        display: none;
    }

    scroll-behavior: smooth;
    box-sizing: border-box;
    width: 100%;
    overflow-x: hidden; 
}

.news-page {
    background-color: #fff;
    min-height: 100vh;
    width: 100vw;
    color: #000;
    font-family: Montserrat;

    .hero-carousel {
        height: 742px;
        display: flex;
        min-width: fit-content;
        height: 100vh;
        transition: .25s ease all;
        .hero {
            width: 100vw;
            height: 100%;
            background-position: center;
            background-size: cover;
            position: relative;
            background-attachment: fixed;
            .overlay {
                background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%), rgba(0, 0, 0, 1) 50%/cover no-repeat;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: .5;
                opacity: .3;
            }

            .titles-container {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                font-family: Montserrat;
                padding: 74.2px 79px;

                h3 {
                    font-size: 35px;
                    font-weight: 700;
                    text-transform: capitalize;
                    color: #fff;
                    margin: 0;
                    width: 60%;
                    display: flex;
                    // justify-content: space-around;
                }

                h5 {
                    font-size: 14.533px;
                    font-weight: 400;
                    line-height: 100.9%;
                    color: #fff;
                    margin: 0;
                    margin-bottom: 6.8px;
                }

                @media only screen and (max-width: 426px) {
                    padding: 0px 20px 45px 7px; 

                    h3 {
                        width: 100%;
                    }
                }
            }
        }

        .hero-navigator {
            position: absolute;
            height: 100%;
            display: flex;
            padding: 68px;
            transition: .25s ease all;
            
            .hero-buttons {
                margin: auto;
                
                div {
                    width: 11px;
                    height: 11px;
                    background-color: #fff;
                    border-radius: 50%;
                    opacity: .7;
                    margin-bottom: 15px;
                    cursor: pointer;
                    transition: .25s ease all;
                    &:hover {
                        opacity: 1;
                    }

                    &.selected {
                        transform: scale(1.5);
                        opacity: 1;
                    }
                } 
            }
        }
    }

    section {
        display: flex;
        padding: 0 10%;
        
        @media only screen and (min-width: 426px) and (max-width: 1000px) {
            padding: 0 10% !important;
            display: block !important;
        }

        @media only screen and (max-width: 1100px) {
            padding: 0 5%;
        }

        @media only screen and (max-width: 426px) {
            padding: 0 4%;
            display: block !important;
        }


        .articles-list {
            width: 70%;
            max-width: 70%;
            padding: 30px 15px;
            padding-bottom: 0;

            &.recent {
                max-width: 30%;

                @media only screen and (max-width: 1000px) {
                    max-width: unset;
                }

                .border {
                    bottom: -2px !important;
                    height: 1.5px !important;
                }
            }

            &.movies {
                width: 100%;
                padding-top: 0;
            }

            &.side-options {
                width: 100%;
                max-width: unset;
                //min-width: 430px;
                padding-top: 0;
            }

            .title-container {
                padding-bottom: 16.6px;
                border-bottom: 2px solid #393939;
                position: relative;

                h3 {
                    font-size: 29px;
                    font-family: Montserrat;
                    font-weight: 800;
                    text-transform: capitalize;
                    margin: 0;
                    display: flex;

                    .arrows {
                        display: flex;
                        margin: auto;
                        margin-right: 0;
                        div {
                            width: 28.656px;
                            height: 28.656px;
                            border-radius: 7px;
                            background-color: #2D2D2D;
                            color: #fff;
                            font-size: 16px;
                            opacity: .6;
                            display: flex;
                            transition: .25s ease all;
                            cursor: no-drop;
                            i {
                                margin: auto;
                            }

                            &.active {
                                cursor: pointer;
                                opacity: .9;

                                &:hover {
                                    opacity: 1;
                                }
                            }

                            &:last-of-type {
                                margin-left: 12px;
                            }
                        }
                    }
                }

                .border {
                    width: 168px;
                    height: 2px;
                    //background-color: #F65050;
                    position: absolute;
                    bottom: -2.5px;
                    left: 0;
                }

                &.navigation {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 7px;
                    padding-bottom: 25px;

                    .switch {
                        color: #000;
                        font-size: 16.805px;
                        font-family: Montserrat;
                        font-weight: 700;
                        transition: .25s ease all;
                        cursor: pointer;
                        /*width: 33.333%;
                        text-align: center;*/
                        &.active {
                            color: #232360;
                            font-weight: 800;
                        }

                        /*&:first-of-type {
                            text-align: left;
                        }

                        &:last-of-type {
                            text-align: right;
                        }*/
                    }
                }

                
            }

            .content {
                display: flex;
                padding: 30px 0;
                .main-article {
                    width: 45%;
                    min-width: 45%;
                    margin-right: 5%;
                    box-sizing: border-box;
                    cursor: pointer;
                    transition: .25s ease all;

                    @media only screen and (min-width: 426px) {
                        &:hover {
                            transform: scale(1.05);    
                        }
                    }
                    
                    .image {
                        border-radius: 20px;
                        background-color: #e1e1e1;
                        background-position: center;
                        background-size: cover;
                        // max-width: 471.736px;
                        height: 300.086px;
                        width: 100%;
                    }

                    .details {
                        padding: 30px 0;
                        width: 90%;

                        .author {
                            font-size: 12.003px;
                            font-family: Montserrat;
                            font-weight: 400;
                            color: #2D2D2D;
                            margin-bottom: 7px;
                            span {
                                color: #39393999;
                            }
                        }

                        .title {
                            margin-bottom: 20px;
                            color: #393939;
                            font-size: 21.606px;
                            font-family: Montserrat;
                            font-weight: 700;
                        }

                        .text {
                            color: #000;
                            font-size: 16.805px;
                            font-family: Montserrat;
                            font-weight: 200;
                        }
                    }
                }

                .other-articles {
                    .article-container {
                        display: flex;
                        margin-bottom: 19px;
                        cursor: pointer;
                        transition: .25s ease all;

                        @media only screen and (min-width: 426px) {
                            &:hover {
                                transform: scale(1.05);    
                            }
                        }

                        .image {
                            border-radius: 20px;
                            width: 108.031px;
                            height: 84.024px;
                            min-width: 108px;
                            min-height: 84px;
                            margin-right: 23px;
                            background-color: #e1e1e1;
                            background-position: center;
                            background-size: cover;
                        }

                        .details {
                            p {
                                margin: 0;
                                color: #2D2D2D;
                                font-size: 12.003px;
                                font-family: Montserrat;
                                font-weight: 400;
                                margin-bottom: 5px;
                                span {
                                    color: #39393999;
                                }
                            }

                            h4 {
                                color: #393939;
                                font-size: 18.005px;
                                font-family: Montserrat;
                                font-weight: 600;
                                text-transform: capitalize;
                                margin: 0;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                }

                &.movie {
                    display: block;
                    .main-movie {
                        width: 100%;

                        .poster {
                            width: 100%;
                            height: 540px;
                            border-radius: 20px;
                            background-color: #e1e1e1;
                            background-position: center;
                            background-size: cover;
                           
                        }
                    }

                    .movies-gallery-container {
                        max-width: 100%;
                        overflow-x: scroll;
                        scroll-behavior: smooth;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        .other-articles {
                            display: flex;
                            min-width: fit-content;
                            justify-content: space-evenly;
                            padding: 33px 0;
                            padding-bottom: 0;
                            .article-container {
                                margin: auto;
                                margin-left: 5px;
                                width: 450px;

                                h4 {
                                    text-transform: unset;
                                }

                                .image {
                                    min-width: 146.519px;
                                    min-height: 120.035px;
                                    box-sizing: border-box;
                                }
                            }
                        }
                    }
                }

                .download-card {
                    border-radius: 10px;
                    background: #FF9696;
                    min-height: 268px;
                    width: 100%;
                    padding: 6px 5%;
                    color: #fff;
                    transition: .5s ease all;
                    cursor: pointer;
                    max-width: 350px;

                    @media only screen and (max-width: 468px) {
                        height: 268px;
                        margin: auto;
                    }
                    
                    &:hover {
                        transform: scale(1.05);

                        .cover-preview {
                            i {
                                transform: scale(1.15);
                            }
                        }
                    }

                    .cover-preview {
                        position: relative;
                        padding: 10px 0;
                        border-bottom: 2px solid #fff;
                        display: flex;
                        height: 80%;
                        h2 {
                            font-family: Montserrat;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 163.9%; /* 16.39px */
                            position: absolute;
                            bottom: 0;
                            margin-bottom: 10px;
                        }

                        i {
                            margin: auto;
                            font-size: 45px;
                            transition: .5s ease all;
                            text-shadow: -2px 5px 19px rgba(0,0,0,0.45);
                        }
                    }

                    .bottom {
                        width: 100%;
                        display: flex;
                        height: 20%;

                        .btn {
                            border-radius: 7px;
                            background: #FFF;
                            padding: 8px 15px;
                            display: flex;
                            margin: auto;
                            width: 150px;
                            transition: .25s ease all;

                            &:hover {
                                transform: scale(1.05);
                            }

                            p, i {
                                color: #000;
                                font-family: Montserrat;
                                font-size: 11px;
                                font-weight: 500;
                                margin: auto;
                            }

                            i {
                                margin-right: 7px;
                                font-size: 16px;
                            }

                            &:first-of-type {
                                margin-right: 5px;
                            }

                            &:last-of-type {
                                margin-left: 5px;
                            }

                            &.transparent {
                                background-color: transparent;
                                p {
                                    color: #fff;
                                    text-decoration: underline;
                                }
                                i {
                                    color: #fff;
                                }

                                &:hover {
                                    background-color: #ffffffbd;
                                    p, i {
                                        color: #000;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .side-options-container {
            width: 30%;
            @media only screen and (max-width: 1000px){
                width: 40%;
            }
        }
    }

    section {
        @media only screen and (max-width: 1000px) {
            display: unset;
            
            .articles-list {
                width: unset;
                max-width: unset;
            }
        }

        @media only screen and (max-width: 600px) {
            padding: 30px 10px;
            .articles-list {
                display: unset;
                padding: 30px 0px;
                
                
                .title-container {
                    padding-left: 10px;
                    padding-right: 10px;
                }

                .content {
                    display: unset;
                    padding: 30px 10px;
                    padding-bottom: 0;

                    &.no-flex {
                        display: flex;
                        padding: 30px 10px;
                    }

                    .main-article {
                        margin-right: 0;
                        width: 100%;
                        min-width: unset;
                        padding: 0 10px;

                        .image {
                            width: calc(100% - 30px);
                            margin: auto;
                        }
                        .details {
                            width: unset;
                            padding: 30px 15px;
                        }
                
                    }

                    .other-articles-scroll {
                        overflow-x: scroll;
                        width: 100%;
                        scroll-behavior: smooth;

                        .other-articles {
                            padding: 0 10px;
                            display: flex;
                            width: fit-content;

                            .article-container {
                                // width: 450px;
                                width: 85vw;
                                max-width: 450px;
                                margin-right: 18px;
                            }
                        }
                    }
                }
                
                &.recent {
                    .other-articles {
                        display: block !important;

                        .article-container {
                            width: unset !important;
                        }
                    }
                }
            
                .poster {
                    height: 460px !important;
                    background-position: right !important;
                }
            }
        }
    }
}

.newsletter-card {
    border-radius: 10px;
    background: #F5F5F5;
    width: 100%;
    min-height: 232px;
    text-align: center;
    color: #2D2D2D;
    font-family: Montserrat;
    padding: 30px;

    h3 {
        font-size: 20px;
        font-weight: 500;
    }

    .option {
        width: 100%;
        border-radius: 10px;
        background: #FFF;
        padding: 12px;
        font-size: 14px;
        font-weight: 400;
        box-sizing: border-box;
        outline: none;
        border: 0px;
        font-family: Montserrat;
        
        &::placeholder {
            color: #2D2D2D;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }

        &.btn {
            font-size: 15px;
            font-weight: 700;
            margin-top: 11px;
            color: #fff;
            background-color: #000;
            cursor: pointer;
            transition: .25s ease all;

            &:hover {
                transform: scale(1.05);
            }
        }

    }
}

.newsletter-card-full {
    width: 100%;
    padding-top: 45px;
    display: flex;
    border-top: 3px solid #E8E8E8;


    .illustration-container {
        width: 50%;
        display: flex;
        img {   
            height: 100%;
            margin: auto;
            margin-bottom: 0;
            max-height: 250px;
        }
    }

    .content-container {
        width: 50%;
        min-height: 250px;
        display: flex;

        .content {
            width: 80%;
            color: #000;
            font-family: Montserrat;

            h3 {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 20px;
            }

            p {
                font-size: 15px;
                font-weight: 400;
                line-height: 22.128px; 

                &.error {
                    color: #FF9696 !important;;
                }
            }

            input[type=email] {
                border-radius: 2.012px;
                background: #F4F4F4;
                border: 0px;
                outline: none;
                width: 100%;
                padding: 15px;
                font-family: Montserrat;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: -0.391px;
                text-align: center;
                transition: .25s ease all;
                border: 2px solid white;
                margin-top: 10px;
                &::placeholder {
                    color: #A1A1A1;
                    text-transform: capitalize;
                    font-weight: 400;
                }

                &:focus {
                    border-color: #adc3ff;
                }

                &.error {
                    border-color: #FF9696 !important;
                }
            }

            .submit-btn {
                display: flex;
                margin: 15px 0;
                border-radius: 2.012px;
                padding: 12px;
                background: #000;
                width: fit-content;
                cursor: pointer;
                transition: .25s ease all;
                &:hover {
                    transform: scale(1.05);
                }
                i, p {
                    margin: auto;
                    color: #FFF;
                    text-align: center;
                    font-family: Montserrat;
                    font-size: 13px;
                    font-weight: 500;
                    letter-spacing: 1.609px;
                    text-transform: uppercase;
                }

                p {
                    margin-left: 5px;
                }

                i {
                    margin-left: 5px;
                    margin-right: 5px;
                    font-size: 18px;
                }
            }
        }
    }

    
    @media only screen and (max-width: 768px) { 
        .illustration-container {
            display: none;
        }

        .content-container {
            width: 100%;

            .content {
                margin: auto;
            }
        }
    }
}

.newsletter-card-article {
    width: 100%;
    background-color: #FFF;
    filter: drop-shadow(0px 2.011641263961792px 14.081488609313965px rgba(42, 42, 42, 0.24));
    border-radius: 20px;
    margin-top: 30px;
    margin-bottom: 37px;
    padding: 40px 35px;
    font-family: Montserrat;

    h3, h5, p {
        margin: 0;
    }

    h3 {
        font-size: 18.105px;
        font-weight: 600;
        line-height: 27.157px;
    }

    h5 {
        font-size: 13.076px;
        font-weight: 400;
        opacity: .7;
        text-transform: capitalize;
    }

    p {
        font-size: 15px !important;
        font-weight: 400;
        line-height: 22.128px;
        margin-top: 13px;
    }

    .buttons {
        display: flex;
        margin-top: 30px;

        .input {
            border-radius: 2px;
            background: #F4F4F4;
            padding: 12px;
            width: 50%;
            border: 0;
            outline: 0;
            margin-top: 30px;
            text-align: center;
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 400;
            line-height: 15.087px;
            letter-spacing: -0.391px;
            text-transform: capitalize;
            margin: auto;
            margin-left: 0;
            border: 2px solid white;

            &::placeholder {
                color: #A1A1A1;
            }

            &:focus {
                border-color: #adc3ff;
            }

            &.error {
                border-color: #FF9696 !important;
            }
        }

        .btn {
            margin: 0 auto;
            cursor: pointer;
            border-radius: 2.012px;
            background: #000;
            width: 40%;
            display: flex;
            color: #fff;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            transition: .25s ease all;
            &:hover {
                transform: scale(1.05);
            }

            i {
                margin: auto;
                font-size: 18px;
            }

            div {
                margin: auto;
                margin-left: 0;
                text-transform: uppercase;
            }
        }
    }
    
    @media only screen and (max-width: 468px) {
        border-radius: 10px !important;
        padding: 30px  !important;

        h3 {
            font-size: 16px  !important;
        }

        h5 {
            font-size: 12px !important;
        }

        p {
            font-size: 14px !important;
            margin-top: 7px !important;
        }

        .buttons {
            margin-top: 20px !important;
            display: unset !important;

            .input {
                width: 100% !important;
                box-sizing: border-box !important;
                margin-bottom: 10px !important;
                margin-top: 10px !important;
            }

            .btn {
                width: 100% !important;
                padding: 10px !important;
            }
        }

        .btn {
            font-size: 12px !important;

            i {
                font-size: 13px !important;
                margin-right: 5px !important;
            }
        }
    }
}

.article-page {
    background-color: #fff;
    min-height: 100vh;
    width: 100vw;
    color: #000;
    font-family: Montserrat;
    position: absolute;
    left: 0;
    top: 0;

    .article {
        padding: 100px 15vw;
        padding-bottom: 0 !important;
        max-width: 1500px;
        margin: auto;

        .input-text {
            display: block;
            background: transparent;
            outline: none;
            border: 2px solid transparent;
            transition: .25s ease all;
            font-family: 'Montserrat';
            resize: none;
            &:focus, &:hover {
                border-color: #5c7af1b4;
            }
        }

        @media only screen and (max-width: 1100px) {
            padding: 100px 10vw;
        }

        @media only screen and (max-width: 992px) {
            padding: 100px 5vw;
        }
        
        @media only screen and (max-width: 768px) {
            .title-container {
                h4, .title {
                    font-size: 35px !important;
                }
            }

            .image-container {
                .image {
                    height: 300px  !important;
                }
            }

            .content-container {
                .content {
                    width: 100% !important;
                    font-size: 14px !important;
                }

                .recommendations {
                    display: none !important;
                }
            }
        }

        @media only screen and (max-width: 426px) {
            .title-container {
                h4, .title {
                    font-size: 25px !important;
                }
            }
        }

        .title-container {
            padding-bottom: 40px;
            h4, .title {
                font-size: 42.244px;
                font-weight: 600;
                text-align: center;
                width: 95%;
                margin: auto;
                &::first-letter {
                    text-transform: uppercase;
                }
            }
        }

        .image-container {
            display: flex;
            .image {
                border-radius: 15px;
                margin: 0 auto;
                width: 100%;
                height: 400px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                background-color: #F1F1F1;
                transition: .25s ease all;
                &.edit {
                    cursor: pointer;
                    border: 2px solid transparent;

                    &:hover {
                        border-color: #5c7af1b4;
                        transform: scale(1.02);
                    }
                }
                &.selected {
                    border-color: #5c7af1b4;
                }
            }
        }

        .details {
            padding-top: 40px;
            font-size: 13px;
            width: 100%;
            display: flex;
            font-family: Poppins;
            color: #2A2A2A;
            font-weight: 400;

            .date {
                margin-right: 10px;
            }

            .user, .length {
                margin-right: 7px;
                opacity: .7;
                text-transform: capitalize;
            }


            @media only screen and (max-width: 426px) {
                font-size: 11px;
            }
        }

        .content-container {
            padding-top: 13px;
            display: flex;
            justify-content: space-between;
            .content {
                line-height: 27.157px;
                width: 65%;
                white-space: pre-line;
                font-family: Montserrat;
                p {
                    font-size: 18.105px;
                    font-weight: 400;
                    font-family: Montserrat;

                    &::first-letter {
                        text-transform: capitalize;
                    }
                }

                h1, h2, h3, h4 {
                    font-family: "Montserrat";
                }

                .ql-picker {
                    display: none;
                }

                img, .ql-video {
                    width: 100%;
                    border-radius: 15px;
                }

                .ql-video {
                    min-height: max(25vw, 60vh);
                }

                .ql-formats {
                    .ql-video {
                        min-height: unset;
                        width: unset;
                    }
                }
            }

            .impressions {
                width: 100%;
                display: flex;
                padding-top: 44px;
                padding-bottom: 26px;
                border-bottom: 1px solid #2a2a2a45;
                justify-content: space-between;

                @media only screen and (max-width: 426px) {
                    padding-top: 4px;   
                    padding-bottom: 16px; 

                    .child {
                        .icon {
                            margin-right: 15px !important;
                        }
                    }
                }

                .child {
                    display: flex;
                    .icon {
                        display: flex;
                        margin-right: 40px;
                        transition: .25s ease all;
                        cursor: pointer;
                        &:hover {
                            transform: scale(1.1);
                        }
                        p {
                            color: #2A2A2A;
                            font-family: Montserrat;
                            font-size: 11px;
                            font-weight: 500;
                            margin: auto;
                        }
                        i {
                            color: #2A2A2A;
                            font-size: 25px;
                            margin: auto;
                            margin-right: 6px;
                            opacity: .8;
                        }
                    }
                }

                &:first-child {

                }
            }

            .author-details {
                width: 100%;
                display: flex;
                margin: 33px 0;

                @media only screen and (max-width: 426px) {
                    padding-top: 4px;   
                    padding-bottom: 16px; 

                    .child {
                        .icon {
                            margin-right: 15px !important;
                        }
                    }
                }

                .profile-picture-container {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    overflow: hidden;
                    box-sizing: border-box;

                    &.edit {
                        cursor: pointer;
                        border: 2px solid transparent;
                        transition: .25s ease all;

                        &:hover {
                            border: 5px solid #5c7af1b4;
                            //transform: scale(1.05);
                        }
                    }

                    &.selected {
                        border: 5px solid #5c7af1b4;
                    }
                }

                .profile-picture {
                    width: 80px;
                    height: 80px;
                    min-width: 80px;
                    min-height: 80px;
                    transform: scale(1.1);
                    //border-radius: 50%;
                    background-color: #e1e1e1;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .author {
                    margin-left: 22px;
                    width: 80%;
                    h5, p, span {
                        margin: 0;
                        color: #2A2A2A;
                        font-family: Montserrat;
                        line-height: 22.128px;
                        &::first-letter {
                            text-transform: capitalize;
                        }
                    }
                    
                    h5 {
                        text-transform: capitalize;
                    }

                    span {
                        opacity: .7;
                        font-size: 13px;
                        font-weight: 500;
                    }

                    h5, .name-input {
                        font-size: 20px;
                        font-weight: 700;
                    }

                    .name-input {
                        width: 100%;
                    }

                    p, .desc-input {
                        font-size: 15px;
                        font-weight: 400;
                        margin: 11px 0;
                        width: 100%;
                    }
                }
            }

            .recommendations {
                width: 27%;
                font-family: Montserrat;
                display: block;

                h4 {
                    margin: auto;
                    color:  #2A2A2A;
                    font-size: 18.105px;
                    font-weight: 700;
                    margin-bottom: 10px;
                }

                .element {
                    width: 100%;
                    margin-bottom: 25px;
                    transition: .25s ease all;
                    display: block;
                    &:hover {
                        transform: scale(1.05);
                    }

                    .image {
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-color: #F1F1F1;
                        border-radius: 15px;
                        width: 100%;
                        padding-bottom: 50%;
                        max-height: 200px;
                    }

                    h5 {
                        margin: 15px 0;
                        font-size: 15px;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .related-articles-container {
        padding: 25px 35px;
        width: fit-content;
        margin: auto;
        margin-left: 0;
        padding-left: 8%;
        padding-right: 8%;

        @media screen and (max-width: 992px) {
            padding-left: 6%;
            padding-right: 6%;
        }
        width: 100%;
        h4 {
            color: #2A2A2A;
            font-size: 18.105px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        .related-articles {
            display: flex;
            flex-wrap: wrap;

            .element {
                width: 304px;
                margin-right: 25px;
                transition: .25s ease all;
                display: block;

                @media only screen and (max-width: 468px) {
                    width: 100%;
                    margin: 0;
                } 

                &:hover {
                    transform: scale(1.05);
                }

                .image {
                    width: 100%;
                    height: 201px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-color: #F1F1F1;
                    border-radius: 15px;
                }

                h5 {
                    margin: 15px 0;
                    font-size: 15px;
                    font-weight: 600;
                }
            }
        }
    }
}

.author-edit-options {
    display: flex;
    padding: 20px 10px;

    .btn {
        background: rgb(223, 223, 223);
        color: rgb(57, 57, 57);
        border-radius: 2px;
        font-size: 10px;
        text-align: center;
        cursor: pointer;
        display: flex;
        width: 30%;
        opacity: 0.85;
        font-weight: 700;
        margin: auto;
        transition: .25s ease all;

        &.add {
            width: 50px;
            height: 38.5px;
            margin-right: 0;
            margin-left: 10px;
            i {
                margin: auto;
                font-size: 18px;
            }
        }
        &:hover {
            opacity: 1;
        }
    }

    .authors-list-container {
        margin: auto;
        margin-right: 10px;
        font-family: Montserrat;
        font-size: 13px;
        position: relative;
        .opener, .list {
            padding: 5px 20px;
            background: rgb(223, 223, 223);
            color: rgb(57, 57, 57);
            border-radius: 2px;
            cursor: pointer;
            transition: .25s;
            opacity: .85;
            display: flex;
            text-align: center;

            &:hover {
                opacity: 1;
            }

            i {
                margin: auto;
                margin-left: 5px;
                font-size: 18px;
            }
        }

        .list {
            display: none;
            position: absolute;
            top: 120%;
            width: 100%;
            background: #191d29;
            border-radius: 3px;
            filter: drop-shadow(0 0 11px rgba(0, 0, 0, 0.5));
            text-align: left;
            color: #fff;
            padding: 0;
            max-height: 250px;
            overflow-y: scroll;
            z-index: 1;
            &::-webkit-scrollbar {
                display: none;
            }
            div {
                padding: 9px 20px;
                text-transform: capitalize;
                cursor: pointer;
                transition: .25s ease all;

                &.selected {
                    font-weight: 700;
                }

                &:hover {
                    background-color: rgba(0,0,0,0.5);
                }
            }
            &.open {
                display: block;
                animation: appear .25s ease-in-out;
            }
        }
    }
}