.modal-overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    animation: fade .5s ease-in-out;
    display: flex;
    pointer-events: all;

    .modal-container {
        position: relative;
        margin: auto;
        background-color: #FFFFFF;
        width: 50vw;
        height: fit-content;
        max-width: 1072px;
        max-height: 632px;
        border-radius: 25px;
        padding: 25px;
        padding: 37px 30px;
        animation: appear .5s ease-in-out;
        overflow: hidden;

        &.gallery {
            height: 80vh;

            @media only screen and (max-width: 768px) {
                height: 65vh;
            }
        }
        

        @media only screen and (max-width: 768px) {
            width: 90vw;
        }
        
        h3 {
            margin-top: 0;
            margin-bottom: 1em;
        }

        p {
            margin-bottom: 1em;
        }

        .btns-container {
            display: flex;
            justify-content: center;
            margin-top: 10px;
        }

        .btn {
            font-size: 16px;
            cursor: pointer;
            transition: .5s ease all;
            padding: 14px 30px;
            border-radius: 26.5px;
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 52px;
            height: 52px;

            @media only screen and (max-width: 376px) {
                font-size: 15px;
                padding: 10px;
            }

            &.confirm {
                background: #000;
                color: #fff;
            }

            &.cancel {
                margin-right: 10px;
            }

            &:hover {
                background: #343434;
                color: #fff;
            }

            &.disabled {
                cursor: no-drop;
                opacity: .5;
            }
        }

        .images-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            overflow-y: scroll;
            padding-bottom: 5%;
            max-height: 100%;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            .image {
                width: 31%;
                padding-bottom: 40%;
                background-color: #e1e1e1;
                background-position: center;
                background-size: cover;
                margin: 1%;
                border-radius: 5px;
                cursor: pointer;
                transition: .25s ease all;
                &:hover {
                    transform: scale(1.05);
                }

                &.banner {
                    width: 48%;
                    padding-bottom: 30%;
                }
            } 
        }
    }
}