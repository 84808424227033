div {
    box-sizing: border-box;
}

.map-container {
    width: 100vw;
    height: 100vh;
    user-select: none;
    background-color: #FFFEFC;

    .map-filter {
        background: #083eff;
        opacity: 0.05;
        pointer-events: none;
        width: 100vw;
        height: 100vh;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        opacity: 0;
    }

    .map-ui {
        z-index: 1;
        width: 100%;
        height: 100%;
        position: fixed;
        user-select: none;
        pointer-events: none;
        position: relative;
        overflow: hidden;
    }

    .navigation-ui-container {
        width: 100vw;
        position: absolute;
        bottom: 0;
        box-sizing: border-box;
        transform: translateY(53%);
        transition: .25s ease-in-out all;
        z-index: 1;

        @media only screen and (max-width: 768px) {
            transform: translateY(35%);
        }

        &.tutorial {
            transform: translateY(56%);

            .navigation-ui {
                margin-bottom: 20px;
            }
        }

        &.open {
            transform: translateY(0%);

            .navigation-ui {
                position: relative;

                .toggle-footer {
                    background-color: #ffffff88;

                    i {
                        transform: rotate(180deg);
                        transition: .25s ease all;
                    }
                }
            }

        }

        .navigation-ui {
            display: flex;
            padding: 27px 20px;
            max-width: 100vw;
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            @media only screen and (max-width: 490px) {
                pointer-events: all;
            }
        }

        .filter-buttons-container {
            display: inline-flex;
            margin: auto 0;
            pointer-events: all;

            @media only screen and (max-width: 768px) {
                margin: auto;
            }
        }

        .filter-button {
            opacity: .85;
            /*width: 66.73px;
            height: 66.73px;*/
            padding: 21px;
            background-color: #fff;
            border-radius: 33.3648px;
            box-shadow: 4px 7px 10px rgba(73, 73, 73, 0.06);
            margin-right: 22.85px;
            margin-right: 1vw;
            display: flex;
            transition: .2s ease all;
            cursor: pointer;
            pointer-events: all;

            @media only screen and (max-width: 768px) {
                padding: 17px;
                margin-right: 10px;
            }

            &:hover {
                opacity: 1;
                transform: scale(1.05);

                i {
                    transform: scale(1.15);
                }
            }

            i {
                font-size: 24px;
                color: #000;
                margin: auto;
                transition: .35s ease all;
            }

            &.active {
                background-color: #000;
                color: #FFFFFF;

                i {
                    color: #FFFFFF;
                }
            }

            &.options {}
        }

        .filter-properties {
            display: flex;
            animation: fadeIn .25s ease-in-out;
            transition: .1s ease all;

            .filter-properties-scroll {
                display: flex;
                margin: auto;


                padding: 0 27px;

                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            @media only screen and (max-width: 1450px) {
                position: absolute;
                transform: translateY(-110%);
                left: 0;

                .filter-properties-scroll {
                    width: 100vw;
                    overflow-x: scroll;
                    pointer-events: all;
                }
            }
        }

        .filter-property-container {
            background-color: #fff;
            border-radius: 15px;
            box-shadow: 4px 7px 10px rgba(73, 73, 73, 0.06);
            opacity: .85;
            transition: .15s ease all;
            cursor: pointer;
            width: 387px;
            min-width: 387px;
            max-width: 387px;
            box-sizing: border-box;
            font-family: 'Montserrat';
            font-weight: 400;
            font-size: 12px;
            padding: 9px 15px;
            margin: auto 0;
            //height: 88.2px;
            margin-right: 12px;
            pointer-events: all;
            transition: .2s ease all;
            box-sizing: content-box;

            &.small {
                width: 124px;
                max-width: unset;
                min-width: unset;

                @media only screen and (max-width: 1450px) {
                    width: 92px;
                    min-width: 92px;
                }
            }

            h4 {
                font-family: 'Montserrat';
                font-weight: 500;
                font-size: 12px;
                margin: 0;
                margin-bottom: 5px;
            }

            .filters {
                display: flex;
                flex-wrap: wrap;
            }

            .checkbox-container {
                display: flex;
                cursor: pointer;
                margin: auto;
                margin-left: 0;
                margin-right: 4px;

                .checkbox {
                    margin: auto;
                    margin-right: 4px;
                    font-size: 25px;
                    transition: .25s ease all;
                    color: #D9D9D9;

                    &.checked {
                        color: #428BFF;
                    }
                }

                p {
                    margin: auto;
                }
            }
        }

        .filter-property-container {
            @media only screen and (max-width: 768px) {
                font-size: 14px;
                height: unset;

                h4 {
                    font-size: 14px;
                }
            }
        }


        .tutorial-mode-btn {
            margin: auto;
            display: flex;
            padding: 14px 25px;
            border-radius: 42px;
            transition: .2s ease all;
            background-color: black;
            color: #fff;
            font-size: 17px;
            font-family: 'Montserrat';
            font-weight: 400;
            cursor: pointer;
            opacity: .86;
            transition: .25s ease all;
            margin-right: 0;
            min-width: 151px;
            pointer-events: all;
            box-sizing: content-box;

            @media only screen and (max-width: 768px) {
                display: none;
            }

            &:hover {
                opacity: 1;
            }

            div,
            i {
                margin: auto;
            }

            .icon {
                background-color: #fff;
                border-radius: 50%;
                width: 22px;
                height: 22px;
                display: flex;
                margin-right: 11.42px;
            }

            i {
                margin: auto;
                font-size: 18px;
                color: black;
            }
        }

        .toggle-footer {
            font-size: 45px;
            color: #fff;
            display: flex;
            border: 2.8px solid #FFFFFF;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            margin: auto;
            background-color: #c4c3c390;
            backdrop-filter: blur(1px);
            cursor: pointer;
            margin: auto 15px;
            transition: .25s ease all;
            pointer-events: all;

            @media only screen and (max-width: 768px) {
                display: none;
            }

            i {
                margin: auto;
                transition: .35s ease all;
            }

            &:hover {
                transform: scale(1.05);

                i {
                    transform: scale(1.15);
                }
            }
        }
    }

    .footer-container {
        width: 100vw;
        display: flex;

        @media only screen and (max-width: 768px) {
            opacity: 0;
        }

        .footer {
            position: relative;
            width: 90%;
            background: #FFFFFF;
            border-radius: 31.9688px;
            margin: auto;
            margin-bottom: 2vh;
            padding: 30px;
            padding-bottom: 18px;
            display: flex;
            flex-direction: column;
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 10.9607px;
            pointer-events: all;

            .links-container {
                margin: auto;
                margin-bottom: 32px;
                margin-top: 10px;

                a {
                    text-align: center;
                    color: #000000;
                    text-decoration: none;
                    margin-right: 22px;
                    pointer-events: all;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .rights-container {
                text-align: center;
                font-weight: 700;
                font-size: 12.7875px;
            }

            .verse-logo-container {
                position: absolute;
                left: 0;
                padding: 0 20px;
                display: flex;
                height: 100%;
                top: 0;

                .verse-logo {
                    width: 50.22px;
                    margin: auto;
                    margin-right: 14.4px;
                }

                .provided-container {
                    font-weight: 700;
                    font-size: 10.9607px;
                    line-height: 13px;
                    margin: auto;

                    h3 {
                        color: #8D8D8D;
                        margin: 0;

                        span {
                            color: #fd4032;
                        }
                    }

                    h4 {
                        color: #000000;
                        margin: 0;
                    }
                }
            }
        }
    }

    .property-detail {
        position: absolute;
        left: 5vw;
        top: 25vh;
        background-color: #FFFFFF;
        border-radius: 33px;
        width: 412px;
        // min-height: 382px;
        box-sizing: border-box;
        padding: 17px;
        box-shadow: 4px 7px 10px rgb(73 73 73 / 27%);
        pointer-events: all;
        animation: pop .25s ease-in-out;
        opacity: 1;
        transition: .25s ease all;
        top: unset;
        bottom: 17vh;
        z-index: 0;

        &.moving {
            opacity: 0;
        }

        @media only screen and (max-width: 426px) {
            z-index: 99;
            width: 95vw;
            left: 2.5vw;
            top: unset;
            bottom: 1.5vw;
            opacity: 1;
            animation: pop .25s ease-in-out;
        }

        @media only screen and (max-width: 370px) {
            .text-container {
                padding: 10px !important;
            }

            .buttons-container {
                padding: 10px !important;
                display: contents !important;

                .button {
                    width: 100% !important;
                    margin-bottom: 5px;
                }
            }
        }


        &:hover {
            opacity: 1;
        }

        .image-container {
            border-radius: 14px;
            width: 100%;
            height: 136px;
            background-image: url(https://images.pexels.com/photos/2079610/pexels-photo-2079610.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
            background-size: cover;
            background-position: right;
            padding: 8px 10px;
            box-sizing: border-box;
            background-color: #e1e1e1;

            .badge {
                font-family: 'Montserrat';
                font-weight: 700;
                font-size: 16px;
                background-color: #FFA500;
                border-radius: 15.5px;
                color: #FFFFFF;
                text-align: center;
                width: fit-content;
                padding: 6px 36px;
                margin-left: auto;
                margin-right: 0;
            }
        }

        .text-container {
            padding: 16px;

            h2,
            p,
            label {
                font-family: 'Montserrat';
                font-weight: 700;
                font-size: 15px;
                line-height: 19.5px;
                color: #000000;
                margin: 0;
                margin-bottom: 16px;
            }

            p {
                font-weight: 400;
                margin-bottom: 10px;
            }
        }

        .buttons-container {
            display: inline-flex;
            width: 100%;
            justify-content: space-between;
            padding: 16px;
            padding-top: 0;

            .button {
                width: 47%;
                font-family: 'Montserrat';
                font-weight: 300;
                font-size: 16px;
                color: #FFFFFF;
                border-radius: 23.5px;
                padding: 12px;
                display: inline-flex;
                background-color: #2081E2;
                border-color: #2081E2;
                text-align: center;
                border: 1px solid;
                transition: .25s ease all;
                cursor: pointer;

                p {
                    margin: 0;
                    margin: auto;
                }

                .icon {
                    font-size: 15px;
                    margin-right: 12px;
                    background-color: #FFFFFF;
                    margin: auto;
                    margin-right: 0;
                    color: #2081E2;
                    border-radius: 50%;
                    width: 22px;
                    height: 22px;
                    display: flex;

                    i {
                        margin: auto;
                    }
                }

                &:hover {
                    background-color: #0e5397;
                }

                &.close {
                    background-color: transparent;
                    border-color: #000000;
                    color: #000000;

                    &:hover {
                        background-color: #000000;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }

    .info-container {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        transition: .25s ease all;
        backdrop-filter: blur(2px);

        @media only screen and (max-width: 768px) {
            z-index: 100 !important;
        }

        .info-modal {
            @media only screen and (max-width: 768px) {
                padding: 35px !important;
            }

            margin: auto;
            background-color: #FFFFFF;
            border-radius: 45px;
            max-width: 740px;
            width: 90%;
            box-sizing: border-box;
            padding: 43px 40px 28px 60px;
            box-shadow: 4px 7px 10px rgb(73 73 73 / 27%);
            pointer-events: all;
            animation: pop .25s ease-in-out;
            transition: .25s ease all;
            color: #000;

            .title {
                color: #000;
                font-family: Montserrat;
                font-size: 18.005px;
                font-weight: 600;
                padding-bottom: 10px;
                border-bottom: 1px solid #D9D9D9;
            }

            .content {
                text-align: justify;
                font-family: Montserrat;
                font-size: 15px;
                font-weight: 400;
                padding-top: 31px;
                padding-bottom: 31px;
            }

            .buttons {
                display: flex;

                .button {
                    margin: auto;
                    margin-right: 0;
                    border-radius: 18.5px;
                    background: #000;
                    padding: 10px 41px;
                    color: #FFF;
                    font-family: Montserrat;
                    font-size: 15px;
                    font-weight: 400;
                    transition: .25s ease all;
                    cursor: pointer;

                    &:hover {
                        background: #5c5b5b;
                    }
                }
            }

        }
    }

    .tutorial-navigation {
        position: absolute;
        right: 50px;
        width: fit-content;
        top: 118px;
        color: #fff;
        font-size: 14.42px;
        font-family: Montserrat;
        font-weight: 400;

        @media only screen and (max-width: 768px) {
            display: none;
        }

        .step {
            transition: .25s ease all;
            cursor: pointer;
            pointer-events: all;
            text-align: right;
            filter: drop-shadow(0 0 5px rgba(0, 0, 0, .3));

            &.active {
                .children {
                    //height: 150px !important;
                }
            }

            .titles-container {
                display: flex;
                font-weight: 900;
                opacity: .8;
                transition: .25s ease all;

                .title {
                    margin: auto;
                    margin-right: 0;
                    text-transform: capitalize;
                }

                &:hover {
                    opacity: 1;
                }

                .icon {
                    margin: auto;
                    margin-right: 0px;
                    width: 40px;
                    height: 50px;
                    margin: auto;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin-right: 0;
                    position: relative;
                    margin-left: 33px;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        width: 50%;
                        left: 25%;
                        border-bottom: 2px solid #fff;
                    }

                }
            }

            .children {
                font-weight: 400;
                padding-right: 20px;
                padding-right: 49px;
                margin-right: 20px;
                border-right: 2px solid #fff;
                padding-top: 7px;
                transition: .25s ease all;
                height: 0px;
                overflow: hidden;
                text-transform: capitalize;

                div {
                    margin-bottom: 7px;
                    transition: .25s ease all;
                    filter: drop-shadow(0 0 5px rgba(0, 0, 0, .5));

                    &.active {
                        font-weight: 600;
                        font-size: 15px;
                        text-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
                        ;
                    }
                }
            }
        }
    }

    .tutorial-scroll-btn {
        position: absolute;
        width: 100%;
        bottom: 30vh;
        color: #FFF;
        text-align: center;
        left: 0;

        p {
            margin: auto;
            font-family: Montserrat;
            font-size: 13.626px;
            font-weight: 400;
            animation: scrollIdle 4s ease-in-out infinite;
            text-shadow: rgb(0 0 0 / 84%) 0px 3px 8px;
            ;

            padding: 14px 25px;
            border-radius: 42px;
            background-color: #000000de;
            color: #fff;
            font-family: "Montserrat";
            cursor: pointer;
            opacity: 0.86;
            transition: 0.25s ease all;
            width: fit-content;
        }

        i {
            margin: auto;
            font-size: 24px;
            margin-top: 9.6px;
            animation: scrollIdle 4s ease-in-out infinite;
            animation-delay: .5s;
            text-shadow: rgb(0 0 0 / 84%) 0px 3px 8px;
            ;

        }
    }

    .tutorial-titles {
        pointer-events: none;
        user-select: none;
        width: 100%;
        display: grid;
        position: absolute;
        top: 25vh;
        color: #FFF;
        font-family: Montserrat;
        opacity: 1;

        .title {
            margin: auto;
            display: flex;
            font-size: 25px;
            font-weight: 900;
            justify-content: space-between;
            top: 20vh;
            text-shadow: rgb(0 0 0 / 74%) 0px 3px 8px;
            animation: fadeInOut 5s ease-in-out;
            opacity: 0;

            h2 {
                margin: auto;
            }

            .icon {
                width: 50px;
                height: 64px;
                margin: auto;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                margin-left: 15px;
                text-shadow: rgb(0 0 0 / 74%) 0px 3px 8px;

            }

            @media only screen and (max-width: 768px) {
                font-size: 14px;

                .icon {
                    width: 32px;
                    height: 40px;
                }
            }
        }

        p {
            font-size: 18px;
            font-weight: 400;
            width: 40%;
            margin: auto;
            margin-top: 1vh;
            text-align: center;
            text-shadow: rgb(0 0 0 / 84%) 0px 3px 8px;
            animation: fadeInOut 6s ease-in-out;
            opacity: 0;

            @media only screen and (max-width: 768px) {
                font-size: 14px;
                width: 50%;
            }
        }
    }

    .tutorial-titles-card {
        border-radius: 30px;
        background: #ffffff92;
        backdrop-filter: blur(2px);
        color: #000;
        font-family: Montserrat;
        padding: 10px;

        pointer-events: none;
        user-select: none;
        position: absolute;
        bottom: 20vh;
        width: 45vw;
        min-width: 350px;
        left: 50%;
        transform: scale(.8) translateX(-60%);
        transition: .25s ease all;
        display: flex;
        animation: fadeInOut 1.5s ease-in-out;
        padding: 15px 10px;
        bottom: 10vh;

        @media only screen and (max-width: 1100px) {
            width: 60vw;
        }

        @media only screen and (max-width: 768px) {
            width: 80vw;
        }

        @media only screen and (max-width: 490px) {
            width: 90vw;
            transform: scale(1) translateX(-50%);
        }

        .title-container {
            width: 60%;
            margin: auto 0;
            align-self: center;
            align-content: center;
            margin-left: 5%;

            &.without-image {
                width: 100%;
                margin-left: 0 !important;
                .title {
                    h2 {
                        text-align: center !important;
                        width: 100% !important;
                        margin: 0 !important;
                    }
                }

                p {
                    text-align: center;
                }
            }
        }

        .title {
            display: flex;
            margin-bottom: 16px;
            h2 {
                font-size: 25px;
                font-weight: 700;
                letter-spacing: -0.6px;
                margin: auto 0;
            }

            .icon {
                width: 50px;
                height: 64px;
                margin: auto;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                margin-left: 15px;
                margin: auto 0;
                margin-right: 10px;
            }
        }

        p {
            font-size: 18px;
            font-weight: 400;
            letter-spacing: -0.3px;
            margin: 0;
            width: 100%;
            &::first-letter {
                text-transform: capitalize;
            }
        }

        .image {
            width: 210px;
            height: 170px;
            background-color: #a0a0a0;
            box-sizing: border-box;
            border-radius: 28px;
            margin: auto 0;
            background-position: center;
            background-size: cover;
        }
    }

    .map-3d {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }

    .arrow {
        position: absolute;
        height: 100vh;
        width: 3vw;
        cursor: pointer;
        transition: .25s ease all;
        pointer-events: all;
        opacity: 0;
        background: linear-gradient(90deg, rgba(9, 9, 121, 0) 0%, rgba(255, 255, 255, .25) 100%);
        min-width: 10px;
        min-height: 10px;

        &:hover {
            //background-color: #fff;
            opacity: 1;
        }

        &.right {
            right: 0;
            top: 0;
        }

        &.left {
            left: 0;
            top: 0;
            background: linear-gradient(-90deg, rgba(9, 9, 121, 0) 0%, rgba(255, 255, 255, .25) 100%);

        }

        &.top {
            top: 0;
            left: 0;
            width: 100vw;
            height: 3vw;
            background: linear-gradient(0deg, rgba(9, 9, 121, 0) 0%, rgba(255, 255, 255, .25) 100%);
        }

        &.bottom {
            bottom: 0;
            left: 0;
            width: 100vw;
            height: 3vw;
            background: linear-gradient(0deg, rgba(9, 9, 121, 0) 0%, rgba(255, 255, 255, .25) 100%);
            transform: rotate(180deg);
        }
    }
}

.property-annotation-container {
    .property-label {
        background-color: #1b1b1b;
        padding: 14px 25px;
        border-radius: 42px;
        font-family: 'Montserrat';
        font-size: 12px;
        font-weight: 500;
        color: #fff;
        animation: appear .25s ease-in-out;
        width: fit-content;
        margin: auto;
        margin-top: 25px;
        cursor: pointer;
        transition: .25s ease all;

        &:hover {
            transform: scale(1.05);
        }

        i {
            opacity: 1;
            margin-left: 5px;
            transform: translateY(0.6px);
        }
    }

    .property-annotation-details {
        background-color: #FFFFFF;
        border-radius: 15px;
        width: 290px;
        height: 250px;
        box-sizing: border-box;
        box-shadow: 4px 7px 10px rgb(73 73 73 / 27%);
        animation: appear .25s ease-in-out;
        overflow: hidden;

        .image-container {
            background-image: url(https://images.pexels.com/photos/2079610/pexels-photo-2079610.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
            background-size: cover;
            background-position: right;
            padding: 8px 10px;
            box-sizing: border-box;
            background-color: #e1e1e1;
            height: 52%;
            position: relative;

            i {
                position: absolute;
                top: 10%;
                right: 5%;
                color: #090808;
                opacity: .6;
                cursor: pointer;
                transition: .25s ease all;
                animation: none;
                font-size: 21px;

                &:hover {
                    opacity: 1;
                }

                &.active {
                    color: #f04e63;
                    animation: heart .2s ease-in-out;
                    opacity: 1;
                }
            }
        }

        .text-container {
            padding: 16px;

            h2,
            p,
            label,
            h3 {
                font-family: 'Montserrat';
                font-weight: 700;
                font-size: 15px;
                line-height: 19.5px;
                color: #000000;
                margin: 0;

                i {
                    margin: auto 0;
                    font-size: 18px;
                    margin-right: 8px;
                    color: #ff8f00;
                }
            }

            p {
                font-weight: 400;
                margin-bottom: 10px;

                &.id {
                    display: flex;
                    margin-bottom: 0;
                    font-weight: 500;
                    font-size: 10px;
                }
            }

            h2 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            h3 {
                font-weight: 500;
                color: #7c7c7c;
                margin: 0;
                font-size: 10px;
                text-transform: uppercase;
            }
        }
    }

    &.closed {
        .property-annotation-details {
            opacity: 0;
            animation: none;
        }

        .property-label {
            background-color: #FFFFFF;
            color: #000000;
            box-shadow: 4px 7px 10px rgb(73 73 73 / 27%);

            &:hover {
                background-color: #1b1b1b;
                box-shadow: unset;
                color: #FFFFFF;
            }

            i {
                color: #f04e63;
            }
        }
    }
}

.property-annotation-container-official {
    .property-label {
        padding: 7px 12.5px;
        border-radius: 42px;
        font-family: 'Montserrat';
        font-size: 15px;
        font-weight: 700;
        color: rgb(38, 37, 37);
        animation: appear .25s ease-in-out;
        width: fit-content;
        margin: auto;
        margin-top: 25px;
        cursor: pointer;
        transition: .25s ease all;
        opacity: .5;
        display: flex;

        &:hover {
            transform: scale(1.05);
            opacity: 1;
        }

        i {
            margin: auto;
            margin-right: 5px;
            font-size: 18px;
        }
    }

    &.active {
        .property-label {
            background-color: rgb(38, 37, 37);
            color: #fff;

        }
    }
}

.navigation-menu {
    background-color: #FFFFFF;
    border-radius: 33px;
    box-sizing: border-box;
    padding: 17px;
    box-shadow: 4px 7px 10px rgb(73 73 73 / 27%);
    pointer-events: all;
    animation: fade .25s ease-in-out;
    transition: .25s ease all;
    position: absolute;
    opacity: 1;
    transform: scale(.8) translateY(-110%);
    height: fit-content;
    top: 0;

    &:hover {
        opacity: 1;
    }

    .image-container {
        border-radius: 32px;
        background: url(https://images.pexels.com/photos/681335/pexels-photo-681335.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1), lightgray 50% / contain no-repeat;
        box-shadow: 0px -69.97675323486328px 68.13525390625px -16.573440551757812px rgba(0, 0, 0, 0.33) inset;
        width: 295px;
        height: 241px;
        box-sizing: border-box;
        background-size: cover;
        background-position: center;
        position: relative;

        div {
            position: absolute;
            color: #fff;
            bottom: 0;
            width: 100%;
            text-align: center;
            text-transform: lowercase;

            font-size: 19.654px;
            font-family: Montserrat;
            font-weight: 700;
            letter-spacing: -0.393px;
            padding-bottom: 15.5px;

            &::first-letter {
                text-transform: capitalize;
            }
        }
    }

    .menu-container {
        padding: 9px 16px;

        .element {
            color: #000;
            font-size: 13.285px;
            font-family: Poppins;
            font-weight: 500;
            letter-spacing: -0.266px;
            padding: 10px 25px;
            margin-top: 20px;
            border-bottom: 2px solid #EDEDED;
            cursor: pointer;

            &:hover,
            &.selected {
                background-color: #000;
                color: #fff;
                border-bottom-color: #000;
                border-radius: 25px;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.loading-page {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #FFFEFC;
    z-index: 2;
    position: fixed;
    left: 0;
    top: 0;

    .loader {
        width: 250px;
        height: 400px;
        background-image: url(../logo.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin: auto;
        transition: .25s ease all;
        animation: loader 2.5s ease-in-out infinite;
    }
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes heart {
    0% {
        transform: scale(1);
    }

    20% {
        transform: scale(.8);
    }

    80% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes pop {
    0% {
        transform: scale(0);
    }

    70% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(.95);
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    35% {
        opacity: .9;
    }

    85% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes scrollIdle {
    0% {
        transform: translateY(-1vh);
        opacity: 0.8;
    }

    50% {
        transform: translateY(0vh);
        opacity: 1;
    }

    100% {
        transform: translateY(-1vh);
        opacity: 0.8;
    }
}

@keyframes loader {
    0% {
        transform: scale(.95);
        opacity: 0.2;
    }

    50% {
        transform: scale(1);
        opacity: 0.5;
    }

    100% {
        transform: scale(.95);
        opacity: 0.2;
    }
}

@keyframes pop {
    0% {
        transform: scale(.9);
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(.9);
    }
}