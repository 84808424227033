a {
    text-decoration: none;
}

footer {
    width: 100%;
    min-height: 417px;
    background-color: #000;
    padding: 6% 4%;
    padding-bottom: 23px;
    padding-right: 0;
    scroll-snap-align: start;
    padding-left: 0;
    padding-top: 5%;
    .footer-container {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        padding-bottom: 6%;
        width: 70%;
        margin: auto;

        @media only screen and (min-width: 1100px){
            width: 50%;
        }

        @media only screen and (max-width: 800px){
            width: 80%;
        }
        
        .logo-container {
            display: flex;
            .logo {
                width: 51.62px;
                height: 79.55px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                background-image: url(../logo.png);
                cursor: pointer;
                opacity: .9;
                margin-right: 30px;
                filter: brightness(0) invert(1);
                &:hover {
                    opacity: 1;
                }
            }
        }

        .links-container {
            width: fit-content;
            font-family: Montserrat, sans-serif;
            h3 {
                color: #fff;
                font-weight: 700;
                font-size: 21.5446px;
                line-height: 26px;
                margin-bottom: 14px;
            }

            .links {
                display: grid;
                a {
                    color: #fff;
                    margin-bottom: 8px;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 15px;
                    opacity: .9;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .rights-reserved {
        text-align: center;
        color: #fff;
        width: 100%;
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 15px;
    }
}
@media only screen and (max-width: 768px) {
    footer{
        padding: 50px 0;
        .footer-container{
            padding: 0;
            margin-bottom: 25px;
            flex-direction: column;
            .logo-container{
                flex-direction: column;
                margin-bottom: 25px;
                gap: 25px;
                .logo{
                    margin: 0;
                    align-self: center;
                }
            }
            .links-container{
                width: 100%;
                h3{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: 18px 30px;
                    font-size: 20px;
                    line-height: 24px;
                    margin-bottom: 0;
                    transition: transform ;
                    cursor: pointer;
                }
                h3::after{
                    content: '';
                    width: 9px;
                    height: 15px;
                    background-image: url(../footer-item-dropdown-arrow.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    transform: rotate(0);
                    transition: transform 0.3s ease-in-out;
                }
                .links{
                    max-height: 0px;
                    opacity: 0;
                    width: fit-content;
                    padding: 0 50px;
                    transform: scale(0);
                    transition: all 0.3s ease-in-out;
                    line-height: 26px;
                    margin-bottom: 0;
                }
                &.expanded{
                    h3::after{
                        transform: rotate(90deg);
                    }
                }
            }
            
        }
    }
}