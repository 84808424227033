body {
    a {
        color: unset;
    }
}
.header-container {
    display: flex;
    width: 100vw;
    justify-content: right;
    z-index: 101;
    position: fixed;
    pointer-events: none;
    
    &.left {
        justify-content: left;
    }

    .logo {
        background-image: url(../logo.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 100px;
        width: 66px;
        margin: 15px 30px;
        //filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));  brightness(0) invert(1)
    }

    .navbar-user {
        width: 782px;
        width: 550px; // temp
        height: 63px;
        background-color: #fff;
        box-shadow: 4px 7px 10px rgba(73, 73, 73, 0.06);
        display: inline-flex;
        border-top-left-radius: -50px;
        border-bottom-left-radius: 50px;
        box-sizing: border-box;
        padding: 0 30px;
        pointer-events: all;
        box-shadow: rgb(100 100 111 / 30%) 0px 7px 29px 0px;
        width: 600px;
        .element {
            margin: auto;
            height: 100%;
            display: flex;
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 12px;
            cursor: pointer;
            color: #000;
            position: relative;
            .button {
                margin: auto;
                display: flex;
                padding: 14px 25px;
                border-radius: 42px;
                transition: .2s ease all;

                div,
                i {
                    margin: auto;
                }

                i {
                    font-size: 18px;
                    margin-right: 11.42px;
                }

                &:hover {
                    background-color: #e1e1e1;
                }

                &.active {
                    background-color: black;
                    color: #fff;
                }

                &.phantom {
                    background-image: url(../phantom.svg);
                    background-size: 50%;
                    background-position: 25% 50%;
                    background-repeat: no-repeat;
                    filter: brightness(0);
                    opacity: .8;
                    transition: .5s ease-in-out all;
                    &:hover {
                        background-color: unset;
                        opacity: .5;
                    }
                }
            }

            .logo {
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                height: auto;
                margin: auto;
                width: 30px;
                filter: unset;
                
                margin-right: 20px;
                margin-left: 20px;

            }

            .profile {
                width: 45px;
                height: 44px;
                border-radius: 50%;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                background-color: #e1e1e1;

                &.active {
                    border: 2px solid;
                }

                &.login {
                    background-color: #fff;
                }
            }

            div {
                margin: auto;
            }

            .menu-options {
                position: absolute;
                width: 125px;
                background-color: #fff;
                right: 0px;
                top: 79px;
                border-radius: 25px;
                transform: translateX(20%);
                padding: 30px 0;
                transition: .25s ease all;
                opacity: .8;

                &:hover {
                    opacity: 1;
                }

                .top {
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid #fff;
                    transform: translateY(-99%);
                    position: absolute;
                    right: 30%;
                    top: 0;
                }
                
                .element {
                    height: fit-content;
                    color: #000;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 400;
                    padding: 0 25px;
                    margin-bottom: 27px;
                    transition: .25s ease all;

                    &:hover {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .mobile-navbar-user {
            width: 100%;
            height: 100px; // temp
            display: flex;
            justify-content: space-between;

            .logo-container {
                background-color: #fff;
                border-radius: 50%;
                filter: drop-shadow(0px 1.856603980064392px 38.98868179321289px rgba(144, 144, 144, 0.25));
                width: 140px;
                height: 140px;
                min-width: 140px;
                min-height: 140px;

                display: flex;
                transition: .25s ease all;
                transform: translate(-29%, -26%);
                box-sizing: border-box;
                z-index: 1;
                pointer-events: all;
                .logo {
                    margin: auto;
                    background-image: url(../logo.png);
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;

                    width: 49%;
                    height: 40%;
                    margin-bottom: 26px;
                    margin-right: 31px;
                    filter: unset;
                    margin: auto;
                    transform: translate(15%, 26%);
                }
            }

            .mobile-buttons {
                display: flex;
                padding: 2vh 5px;
                padding-bottom: 0;
                padding-left: 0;
                pointer-events: all;
                //transform: translateX(-10%);
                .button {
                    background-color: #fff;
                    border-radius: 33.3648px;
                    box-shadow: 4px 7px 10px rgba(73, 73, 73, 0.06);
                    margin: auto;
                    margin-right: 15px;
                    display: flex;
                    transition: .2s ease all;
                    cursor: pointer;
                    width: 60px;
                    height: 60px;

                    &.svg {
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;

                        &.info {
                            background-image: url(../info-icon-svg.svg);
                            background-color: #fff;
                            border-radius: 33.3648px;
                        }

                        &.navigation {
                            background-image: url(../navigation-icon-svg.svg);
                        }

                        &.tutorial {
                            background-image: url(../birdeye-icon.svg);
                        }
                    }

                    &.hamburger {
                        z-index: 1;
                    }

                    i {
                        font-size: 28px;
                        color: #000;
                        margin: auto;
                        transition: 0.35s ease all;
                    }
                }
            }
        }

        .mobile-menu {
            box-sizing: border-box;
            width: 100vw;
            height: 100vh;
            background-color: #F4F4F4;
            z-index: 1;
            position: absolute;
            left: 120%;
            top: 0;
            transition: .2s ease-in-out;
            display: flex;

            color: #000;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 400;
            &.open {
                left: 0;
                pointer-events: all;
                .element {
                    display: flex !important;
                }

                .log-out {
                    display: flex !important;
                }
            }

            .options-container {
                margin: auto;
                width: 50%;
                max-width: 230px;
                @media only screen and (max-width: 350px) {
                    width: 80%;
                }

                .top {
                    width: 100%;
                    .element {
                        display: flex;
                        width: 100%;
                        margin-bottom: 15%;
                        display: none;
                        animation: appear .4s ease-in-out;

                        i {
                            font-size: 30px;
                            margin: auto;
                            margin-left: 0;
                            margin-right: 30%;
                        }

                        div {
                            margin: auto;
                            margin-left: 0;
                        }

                        .profile {
                            width: 36.415px;
                            height: 35.606px;
                            border-radius: 25.304px;
                            background-position: center;
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-color: #e1e1e1;
                            margin-right: 57px;
                        }
                    }
                }

                .log-out {
                    width: 100%;
                    border-top: 1px solid #C9C9C9;
                    display: flex;
                    color: #000;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 500;
                    padding: 30px 15px;
                    margin-top: 10vh;
                    animation: appear 1s ease-in-out;
                    display: none;
                    @media only screen and (max-width: 350px) {
                        margin-top: 10vh;
                    }

                    i {
                        font-size: 25px;
                        margin: auto;
                    }

                    div {
                        margin: auto;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.collection-modal {
    padding: 35px 75px 0 81px;

    &:last-of-type {
        padding-bottom: 50px;
    }

    .input-container-profile {
        color: #232360;
        font-size: 14px;
        font-weight: 500;
        width: 50%;
        margin-bottom: 25px;
        input[type="text"], 
        input[type="password"], 
        input[type="date"], 
        input[type="email"], 
        input[type="number"], textarea {
            border-radius: 9px;
            border: 1px solid #E5E5ED;
            background-color: #f3f4f8;
            color: #768396;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            padding: 13px 17px;
            outline: none;
            width: 100%;
            max-width: 250px;
            height: fit-content;
            &::placeholder {
                opacity: .5;
                font-weight: 400;
            }

            &:focus {
                border-color: #428BFF;
            }

            &:disabled {
                cursor: no-drop;
                background-color: #eaeaed;
            }
        }

        .voucher-container {
            display: flex;
            gap: 10px;
            .btn {
                margin: auto 0;
                cursor: pointer;
                border-radius: 8px;
                border: 1px solid #D9DADE;
                padding: 11px 14px;
                color: #000;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                height: fit-content;
                transition: .25s ease all;

                &.confirm, &:hover {
                    background: #000;
                    border-color: #000;
                    color: #FFFFFF;
                }

                &.confirm {
                    margin-left: 10px;
                    &:hover {
                        transform: scale(1.05);
                    }
                }

                &.disabled {
                    opacity: .5;
                    cursor: no-drop;

                    &:hover {
                        transform: scale(1);
                    }
                }
            }
        }

        .status {
            font-size: 11px;
            i {
                font-size: 15px;
                transform: translateY(2px);
            }
            
            &.error {
                color: #FF1515;
            }

            &.valid {
                color: #00990F;
            }
        }
    }

    .titles-container {
        display: flex;
    }

    .title {
        color: #000;
        font-size: 30px;
        font-weight: 700;
        text-transform: capitalize;
        margin: auto;
        margin-left: 0;
    }

    .filters-container {
        display: flex;
    }

    .filters {
        display: flex;
        flex-wrap: wrap;
        padding: 0 37px;
        margin: auto;

        &.invisible {
            opacity: 0;
            user-select: none;
            pointer-events: none;
        }

        .checkbox-container {
            display: flex;
            cursor: pointer;
            margin: auto;
            margin-left: 0;
            margin-right: 20px;

            .checkbox {
                margin: auto;
                margin-right: 4px;
                font-size: 40px;
                transition: .25s ease all;
                color: #D9D9D9;

                &.checked {
                    color: #000000;
                }
            }

            p {
                margin: auto;
                color: #000;
                font-size: 12px;
                font-family: Montserrat;
                font-weight: 500;
                text-transform: capitalize;
            }
        }
    }

    .search-container {
        display: flex;
        color: #000;
        font-size: 15px;
        font-family: Montserrat;
        font-weight: 400;
        margin: auto;
        margin-right: 0;
        display: none;
        input[type="text"] {
            border-radius: 21.284px;
            background-color: #FFF;
            border: 1px solid #fff;
            font-size: 11.743px;
            font-family: Poppins;
            font-weight: 400;
            line-height: 17.615px;
            text-align: left;
            box-shadow: rgb(100 100 111 / 6%) 0px 5px 10px 0px;
            max-width: 240px;
            height: fit-content;
            padding: 8px 25px;
            box-sizing: border-box;
            margin: auto;

            &:focus {
                outline: transparent;
            }

            &::placeholder {
                text-align: center;
                color: rgba(0, 0, 0, 0.62);
            }
        }

        .pagination {
            margin: auto;
            margin-left: 34px;
        }
    }

    .gallery-container {
        display: flex;
        flex-wrap: wrap;
        padding-top: 6.5px;
        position: relative;
        min-height: 200px;
        
        .element {
            background-color: #e1e1e1;
            background-position: center;
            background-size: cover;
            border-radius: 11.517px;
            width: 133px;
            height: 171px;
            box-shadow: 0px -39px 14px 0px rgba(0, 0, 0, 0.25) inset;
            margin: 16.5px 10px;
            margin-left: 0;
            margin-bottom: 0;
            cursor: pointer;
            transition: .25s ease all;
            position: relative;
            animation: appear .25s ease-in-out;

            &:hover {
                transform: scale(1.025);
            }

            .title-card {
                color: #FFF;
                font-size: 15px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 700;
                margin: 0;
                position: absolute;
                bottom: 0;
                text-align: center;
                padding-bottom: 9px;
                width: 100%;
                text-transform: capitalize;

                border-bottom-left-radius: 11.517px;
                border-bottom-right-radius: 11.517px;
                backdrop-filter: blur(10px);
                padding-top: 9px;
            }

            .badge {
                font-weight: 700;
                font-size: 12.519px;
                font-family: Poppins;
                background-color: #FFA500;
                border-radius: 15.5px;
                color: #FFFFFF;
                text-align: center;
                width: fit-content;
                padding: 6px 26px;
                margin-left: auto;
                margin-right: 0;
                position: absolute;
                top: 9px;
                right: 6px;
            }
        }

        .navigate {
            background-color: #fff;
            border-radius: 50%;
            width: 40.2px;
            height: 40.2px;
            color: #000000;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            transition: .25s ease all;
            cursor: pointer;
            position: absolute;
            top: 45%;
            left: 0;
            z-index: 1;
            display: flex;
            transform: translateX(-50%);

            i {
                margin: auto;
                text-align: center;
                font-size: 25px;
            }

            &:hover {
                background-color: #000000;
                color: #fff;
            }

            &.right {
                right: 0;
                left: unset;
                transform: translateX(50%);
            }

            &.disabled {
                opacity: .3;
                cursor: no-drop;

                &:hover {
                    background-color: #fff;
                    color: #000;
                }
            }
        }
    }
}