.body-dashboard {
    a {
        color: unset;
        text-decoration: none;
    }

    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
    font-family: Montserrat,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #121926;
    background: unset;
    div {
        box-sizing: border-box;
    }

    h6 {
        font-size: 0.75rem;
        font-weight: 400;
        color: #697586;
        font-family: 'Roboto', sans-serif;
        line-height: 1.57;
        margin: 0;
        margin-bottom: 5px;
    }

    h3 {
        margin: 0;
        font-size: 1.25rem;
        color: #121926;
        font-weight: 600;
        font-family: 'Roboto', sans-serif;
        line-height: 1.167;
    }

    h4 {
        margin: 0;
        font-size: 1rem;
        color: #121926;
        font-weight: 600;
        font-family: 'Roboto', sans-serif;
        line-height: 1.235;
    }
}

.dashboard-container {
    width: 100vw;
    min-height: 100vh;
    display: flex;

    .dashboard {
        margin: auto;
        width: 93%;
        min-height: 100vh;
        background-color: #eef2f6;
        margin-top: 80px;
        border-radius: 12px;
        padding: 20px;
        transition: .25s ease all;
        margin-right: 30px;
        overflow-y: scroll;
        height: 100vh;
        scroll-behavior: smooth;
        padding-bottom: 200px;

        @media only screen and (max-width: 426px) {
            margin: 80px auto;
        }

        &::-webkit-scrollbar {
            width: 7.5px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(163, 163, 163, 0.664);
            border-right: 1px solid transparent;
            border-radius: 6px;
        }

        .chart-card {
            background-color: #fff;
            border-radius: 12px;
            padding: 24px;
            width: 100%;
            height: 100%;

            .titles-container {
                padding: 0 50px;
                margin-bottom: 24px;
            }
        }

        .common-card {
            width: 100%;
            height: 185px;
            border-radius: 8px;
            padding: 18px;
            border: none rgba(224, 224, 224, 0.596);
            background-color: rgb(94, 53, 177);
            color: rgb(255, 255, 255);
            margin-bottom: 24px;
            overflow: hidden;
            position: relative;
            &.blue {
                background-color: rgb(30, 136, 229);

                .shapes-background {
                    .circle {
                        background: rgb(21, 101, 192);
                    }
                }
            }

            &.white {
                background-color: #fff;

                .shapes-background {
                    .circle {
                        background: linear-gradient(210.04deg, rgb(255, 193, 7) -50.94%, rgba(144, 202, 249, 0) 83.49%);
                        transform: rotate(90deg);
                    }
                }
            }

            .shapes-background {
                .circle {
                    width: 210px;
                    height: 210px;
                    position: absolute;
                    background: rgb(69, 39, 160);
                    border-radius: 50%;
                    top: -125px;
                    right: -15px;
                    opacity: 0.5;

                    &.second {
                        top: -64px;
                        right: -107px;
                        opacity: 1;
                    }
                }
            }

            .content {
                h4 {
                    color: #fff;
                    margin: 14px 8px 6px 0px;
                    line-height: 1.334em;
                    font-family: Roboto, sans-serif;
                    font-size: 2.125rem;
                    font-weight: 500;

                    span {
                        margin: auto;
                        font-size: 1.5rem;
                        margin-left: 10px;
                    }
                }

                p {
                    margin: 0px;
                    line-height: 1.334em;
                    font-family: Roboto, sans-serif;
                    color: rgb(179, 157, 219);
                    font-size: 1rem;
                    font-weight: 500;
                }

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    background: rgb(69, 39, 160);
                    border-radius: 8px;
                    width: 44px;
                    height: 44px;
                    font-size: 1.5rem;
                    margin-top: 8px;
                }

                &.blue {
                    p {
                        color: rgb(144, 202, 249);
                    }

                    .icon {
                        background: rgb(21, 101, 192);
                    }
                }

                &.white {
                    h4 {
                        color: rgb(18, 25, 38);;
                    }

                    p {
                        color: rgb(105, 117, 134);
                    }

                    .icon {
                        background: rgb(255, 248, 225);
                        color: rgb(255, 193, 7);
                    }
                }
            }
        }
    }
}

.menu {
    margin-top: 80px;
    width: 80px;
    color: rgb(18, 25, 38);
    transition: .25s ease all;
    padding: 0 16px;

    @media only screen and (max-width: 426px) {
        display: none;
    }

    .menu-title {
        margin: 10px 0px 0.35em;
        padding: 6px;
        display: none;
        font-size: 0.875rem;
        font-family: Roboto, sans-serif;
        text-transform: capitalize;
        font-weight: 600;
        opacity: .9;
    }

    &.open {
        width: 260px;
        .menu-title {
            display: block;
        }

        .items-container {
            a {
                width: unset;
                height: unset;
                padding: 10px 16px 10px 24px;
                display: flex;
                justify-content: unset;
                align-items: unset;
                div {
                    display: block;
                    margin: auto 0;
                    font-size: 14px;
                }

                i {
                    margin: auto 0;
                    margin-right: 15px;
                }
            }
        }
    }

    .items-container {
        padding-top: 10px;
        a {
            font-size: 0.875rem;
            font-family: Roboto, sans-serif;
            text-transform: capitalize;
            height: 46px;
            display: flex;
            font-weight: 400;
            align-items: center;
            justify-content: center;
            transition: .25s ease all;
            margin: auto ;
            width: 46px;
            height: 46px;
            border-radius: 8px;
            margin-bottom: 10px;

            &.selected {
                background-color: rgb(237, 231, 246);
                color: rgb(94, 53, 177);
                i{
                    color: rgb(94, 53, 177);
                }

                div {
                    font-weight: 600;
                }
            }

            &:hover {
                background-color: rgb(237, 231, 246);
            }

            i {
                font-size: 20px;
                color: rgb(54, 65, 82);
            }

            div {
                display: none;
                transition: .3s ease all;
                margin-left: 15px;
            }
        }
    }
}

.navbar {
    width: 100%;
    height: 80px;
    position: fixed;
    background: #ffff;
    z-index: 100;
    display: flex;
    padding: 16px;

    .logo {
        max-width: 70px;
        margin: auto;
        margin-left: 0;
        margin-right: 120px;
        @media only screen and (max-width: 560px) {
            margin-right: 50px;
        }
    }

    .menu-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        line-height: 1;
        user-select: none;
        cursor: pointer;
        border-radius: 8px;
        width: 34px;
        height: 34px;
        font-size: 1.2rem;
        overflow: hidden;
        transition: all 0.2s ease-in-out 0s;
        background: rgb(237, 231, 246);
        color: rgb(94, 53, 177);
        margin: auto 0;
        margin-right: 10px;

        &:hover {
            background: rgb(94, 53, 177);
            color: rgb(237, 231, 246);
        }
    }

    .search-input-container {
        display: flex;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.4375em;
        font-family: Roboto, sans-serif;
        color: rgb(54, 65, 82);
        box-sizing: border-box;
        cursor: text;
        display: inline-flex;
        align-items: center;
        position: relative;
        background: rgb(248, 250, 252);
        border-radius: 8px;
        width: 434px;
        margin-left: 16px;
        padding-left: 16px;
        padding-right: 16px;

        border-style: solid;
        border-width: 1px;
        border-color: rgb(189, 189, 189);
        border-radius: 8px;
        transition: .14s ease all;
        @media only screen and (max-width: 560px) {
            display: none;
        }
        &.focused {
            border-color: rgb(33, 150, 243);
            border-width: 2px;
        }
        
        i {
            margin: auto 0;
            color: rgba(0,0,0,0.5);
        }

        .input {
            background: transparent;
            border: 0px;
            outline: none;
            margin: auto 0;
            width: 90%;
            padding-left: 4px;
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            font-weight: 500;
            margin-left: 10px;
            &::placeholder {
                color: rgba(0,0,0,0.3);
                font-weight: 600;
            }
        }

        .menu-icon {
            margin: auto 0;
            &:hover {
                i {
                    color: rgb(237, 231, 246) !important;
                }
                
            }
        }
    }

    .profile-icons-container {
        display: flex;
        margin: auto;
        margin-right: 0;

        .user-avatar-container {
            cursor: pointer;
            color: rgb(33, 150, 243);
            max-height: 48px;
            display: flex;
            border-radius: 27px;
            transition: all 0.2s ease-in-out 0s;
            background-color: rgb(227, 242, 253);
            margin-left: 24px;
            padding: 8px;
            font-family: "Roboto", sans-serif;
            font-size: 13px;
            
            &:hover {
                background-color: rgb(33, 150, 243);
                color: #ffff;
            }
            
            .avatar {
                border-radius: 50%;
                margin: auto 0;
                width: 34px;
                height: 34px;
                background-color: rgb(33, 150, 243);
                border: 1px solid rgb(33, 150, 243);
                background-image: url('https://www.berrydashboard.io/static/media/user-round.13b5a31bebd2cc6016d6db2cac8e92d1.svg');
                background-position: center;
                background-size: cover;
            }

            p {
                margin: auto 10px;
                font-weight: 600;
            }
        }
    }

    @media only screen and (min-width: 600px) {
        padding: 16px 24px;
    }
}