.forum-page {
    min-height: 100vh;
    width: 100vw;
    color: #000;
    font-family: Montserrat;
    background-color: #FFFFFF;
    overflow: hidden;

    .forum-hero {
        background-color: #EEE;
        display: flex;
        height: 65vh;
        position: relative;

        .search-component {
            margin: auto;
            max-width: 888px;
            width: 70%;
            position: relative;
            z-index: 2;

            .searchbar-container {
                width: 100%;
                background-color: #FFFFFF;
                border-radius: 42px;
                display: flex;
                justify-content: space-around;

                .icon {
                    display: flex;
                    width: 11%;

                    i {
                        margin: auto;
                        color: #727272;
                        font-size: 22px;
                    }
                }

                .input {
                    width: 89%;
                    height: 60px;
                    color: #000;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 400;
                    background-color: transparent;
                    outline: none;
                    border: none;

                    &::placeholder {
                        color: rgba(0, 0, 0, 0.42);
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }

            .popular-searches {
                text-align: center;
                color: rgba(0, 0, 0, 0.71);
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                text-transform: capitalize;
                transition: .25s ease all;

                span {
                    font-weight: 400;
                    margin-right: 10px;
                }

                &.white {
                    color: #fff !important;
                }
            }

            @media only screen and (max-width: 768px) {
                .searchbar-container {
                    i {
                        width: 20%;
                    }
                }
            }

            @media only screen and (max-width: 480px) {
                width: 90%;
                .searchbar-container {
                    i {
                        width: 20%;
                    }
                }

                .popular-searches {
                    text-align: left;
                    width: 90%;
                    margin: auto;
                }
            }
        }

        .background {
            background-size: cover;
            background-position: center;
            width: 100%;
            position: absolute;
            bottom: 0;
            z-index: 0;

            @media only screen and (max-width: 768px) {
                width: 250%;

                @media only screen and (max-width: 480px) {
                    width: 350%;
                }
            }
        }

        .modal {
            position: fixed;
            width: 100vw;
            height: 100vh;
            z-index: 1;
            background: rgba(86, 86, 86, 0.81);
            top: 0;
            left: 0;
            animation: fadeIn .15s ease-in-out;
        }
    }

    .navigation-container {
        width: 100%;
        //height: 84px;
        background: #DADADA;
        display: flex;
        padding: 31px 10%;
        color: #000;
        font-family: Montserrat;
        font-size: 17.103px;
        font-weight: 500;

        p {
            margin: auto 0;
            margin: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        i {
            font-size: 20px;
            margin: 0 3px;
            transform: translateY(2px);
        }
    }

    .navigation-container {
        @media only screen and (max-width: 768px) {
            padding: 31px 35px;
        }
    }

    .content {
        // padding: 52px 44px;
        padding: 52px 10%;
        display: flex;

        .main {
            width: 75%;
            height: 100%;
            padding-bottom: 5vh;
        }

        .main,
        .secondary {
            .list-container {
                padding-top: 70px;
            }

            .list-container,
            .category {
                &.responses {
                    padding-top: 0;

                    h4 {
                        margin-bottom: 70px;
                    }

                    .no-results {
                        text-align: center;
                    }

                    &.deleted {
                        opacity: .5;
                    }
                }

                h4 {
                    color: #000;
                    font-family: Montserrat;
                    font-size: 25px;
                    font-weight: 700;
                    text-transform: capitalize;
                }

                .list {
                    .no-results {
                        padding-bottom: 20vh;
                        margin: auto;
                        text-align: center;
                    }

                    span {
                        margin-bottom: 25px;
                    }

                    .table {
                        width: 100%;
                        text-align: left;
                        border-collapse: collapse;

                        thead {
                            tr {
                                background-color: #fff !important;
                                cursor: unset;

                                &:hover {
                                    transform: unset !important;
                                    box-shadow: unset !important;
                                }
                            }
                        }

                        tr {
                            background: #F8F8F8;
                            transition: .25s ease all;
                            cursor: pointer;

                            &:nth-child(2n) {
                                background-color: #fff !important;
                            }

                            &:hover {
                                transform: scale(1.01);
                                box-shadow: 0px 13px 23px 0px rgb(0 0 0 / 6%);
                            }
                        }

                        th {
                            text-align: left;
                            color: #B1B1B1;
                            font-family: Montserrat;
                            font-size: 14px;
                            font-weight: 400;
                            padding: 10px 25px;

                            .status {
                                display: flex;
                                div {
                                    display: flex;
                                    margin-right: 20px;

                                    i {
                                        margin: auto 0;
                                        margin-right: 10px;
                                        font-size: 18px;
                                    }

                                    p {
                                        margin: auto 0;
                                    }

                                    .open, &.open {
                                        i {
                                            color: #FF1515;
                                        }
                                    }

                                    .closed, &.closed {
                                        i {
                                            color: #00990F;
                                        }
                                    }
                                }
                            }
                        }

                        td {
                            color: #404D61;
                            font-size: 14px;
                            font-family: Montserrat;
                            font-weight: 400;
                            padding: 30px 25px;

                            &.title,
                            .title {
                                font-weight: 600;
                                display: flex;

                                div {
                                    margin: auto 0;
                                }
                            }

                            .profilePicture {
                                border-radius: 50%;
                                width: 43px;
                                height: 43px;
                                min-width: 43px;
                                min-height: 43px;
                                background-position: center;
                                background-size: cover;
                                margin: auto 0;
                                background-color: #e1e1e1;
                            }

                            .announcement {
                                display: flex;

                                .details {
                                    margin: auto 0;
                                    margin-left: 18px;

                                    .date {
                                        font-size: 10px;
                                        display: flex;
                                        margin-top: 6px;

                                        i {
                                            font-size: 14px;
                                            margin: auto 0;
                                        }

                                        p {
                                            margin: auto 0;
                                            margin-left: 6px;
                                        }
                                    }
                                }

                                &.comments {
                                    i {
                                        margin: auto 0;
                                        font-size: 16px;
                                    }

                                    p {
                                        margin: auto 0;
                                        font-size: 12px;
                                        margin-left: 6px;
                                    }
                                }

                                &.closed, &.open {
                                    color: #00990F;
                                    font-family: Montserrat;
                                    font-size: 9.948px;
                                    font-weight: 600;

                                    p {
                                        margin: auto 0;
                                    }

                                    i {
                                        font-size: 16px;
                                        margin: auto 0;
                                        margin-right: 5px;
                                    }
                                }

                                &.open {
                                    color: #FF1515;
                                }
                            }

                            .icon {
                                width: 43px;
                                height: 43px;
                                min-width: 43px;
                                min-height: 43px;
                                border-radius: 50%;
                                background-color: #FFA500;
                                margin-right: 11px !important;
                                display: flex;

                                i {
                                    margin: auto;
                                    color: #FFF;
                                    font-size: 19px;
                                }
                            }

                            .last-post {
                                p {
                                    margin: 0;
                                    color: #404D61;
                                    font-size: 13px;
                                    font-weight: 500;
                                }

                                .user {
                                    display: flex;
                                    margin-top: 14px;

                                    p {
                                        color: #000;
                                        margin: auto;
                                        margin-left: 0;
                                        font-weight: 400;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        width: 10vw;
                                    }

                                    .photo {
                                        border-radius: 50%;
                                        width: 26px;
                                        height: 26px;
                                        min-width: 26px;
                                        min-height: 26px;
                                        background-position: center;
                                        background-size: cover;
                                        margin: auto;
                                        margin-left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .secondary {
            width: 25%;
            height: 100%;
            padding: 0 5%;
            padding-right: 0;

            h4 {
                margin: 0;
                margin-top: 20px;
            }

            .list {
                padding: 41px 0;

                .question {
                    display: flex;
                    margin-bottom: 30px;

                    .icon {
                        width: 25px;
                        height: 25px;
                        margin-right: 15.5px;
                        border-radius: 50%;
                        display: flex;
                        margin-right: 15.3px;

                        i {
                            margin: auto;
                            font-size: 12px;
                            color: #fff;
                        }
                    }

                    .text {
                        color: #000;
                        font-family: Montserrat;
                        font-size: 17px;
                        font-weight: 500;
                        width: 70%;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                                line-clamp: 3; 
                        -webkit-box-orient: vertical;
                    }

                    .engagement {
                        margin: auto;
                        margin-top: 0;
                        margin-right: 0;
                        padding: 3px 7px;
                        color: #AAA;
                        background-color: #D9D9D9;
                        font-family: Montserrat;
                        font-size: 14px;
                        font-weight: 400;
                        border-radius: 50%;
                        width: 22.8px;
                        height: 22.8px;
                        text-align: center;
                    }
                }
            }
        }

        .ask-question {
            width: 100%;
            padding: 18px 28px;
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 13px 23px 0px rgba(0, 0, 0, 0.06);
            display: flex;
            justify-content: space-between;

            .first {
                display: flex;
                margin-left: 0;
                width: 60%;
            }

            .btn-container {
                margin: 0;
            }

            img {
                width: 78px;
                margin: auto;
                margin-left: 0;
                margin-right: 45px;
            }

            div {
                margin: auto;
            }

            .text {
                color: #000;
                font-family: Montserrat;
                margin-left: 0;

                .title {
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 6px;
                }

                .subtitle {
                    font-size: 14.7px;
                    font-weight: 500;
                }
            }

            .btn {
                margin-right: 0;
                color: #FFF;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 700;
                border-radius: 7px;
                background: #000;
                padding: 20px 50px;
                cursor: pointer;
                transition: .25s ease all;

                display: flex;
                justify-content: center;
                align-items: center;
                max-height: 62px;
                box-sizing: border-box;

                &:hover {
                    box-shadow: 0px 13px 23px 0px rgba(0, 0, 0, 0.1);
                    transform: scale(1.025);
                }
            }
        }
    }

    .content {
        @media only screen and (max-width: 1024px) {
            display: block;

            .main {
                margin: auto;
                width: unset;
                box-sizing: border-box;

                .list-container {
                    padding-top: 20px;
                }
                
            }

            .secondary {
                width: unset;
                padding: 0;
            }
        }

        @media only screen and (max-width: 768px) {
            padding: 35px 5%;

            .ask-question {
                display: block;
                .btn {
                    display: flex;
                    width: fit-content;
                    margin: auto;
                    margin-top: 20px;
                    margin-right: 0;
                }

                .first {
                    width: 100%;
                }
            }

            .list {
                table {
                    td, th {
                        &:last-of-type {
                            display: none;
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 480px) {
            padding: 35px 5%;
            
            .ask-question {
                padding: 17px;
                .btn {
                    font-size: 9px;
                    padding: 13px 30px;
                    border-radius: 3.845px;
                }
            }

            .pagination-container {
                ul {
                    margin: auto;
                    padding: 0;
                    margin-top: 20px;
                }
            }
        }

        @media only screen and (max-width: 480px) {
            padding: 35px 5%;

            .list {
                table {
                    td {
                        padding: 30px 10px !important;
                    }
                }
            } 
        }        
    }

    .pagination-container {
        display: flex;

        ul {
            display: flex;
            list-style: none;
            margin: auto;
            max-width: 100%;
            margin-right: 0;
            margin-top: 25px;
            transition: .25s ease all;

            li {
                width: 30px;
                height: 30px;
                border-radius: 3.626px;
                border: 0.906px solid #DFE3E8;
                background: #FFF;
                cursor: pointer;
                margin-right: 7.25px;
                font-family: Montserrat;
                font-size: 12.691px;
                font-weight: 700;
                color: #212B36;

                &.selected {
                    border-color: #000;
                }

                &.previous,
                &.next {
                    color: #fff;
                    background-color: #2d3134;

                    &.disabled {
                        background: #FFF;
                        color: #C4CDD5;
                        cursor: no-drop;
                    }
                }

                a {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .topic-body {
        border-radius: 50px;
        background: #F8F8F8;
        width: 100%;
        padding: 37px 30px;
        overflow: hidden;
        transition: .25s ease all;

        a {
            color: #0070E0;
            text-decoration: revert;
        }

        &.deleted {
            opacity: .5;
        }
        &.response {
            margin-bottom: 30px;
        }

        &.loading {
            opacity: .5;
        }

        .container {
            display: flex;
            width: 100%;
        }

        .status {
            display: flex;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 600;
            margin: auto;
            margin-right: 5%;

            p {
                margin: auto 0;
            }

            i {
                font-size: 19px;
                margin: auto 0;
                margin-right: 5px;
            }

            &.closed {
                color: #00990F;
            }

            &.open, &.delete {
                color: #FF1515;
            }
        }

        .user-details {
            display: flex;
            width: 100%;
            .picture {
                border-radius: 50%;
                width: 43px;
                height: 43px;
                min-width: 43px;
                min-height: 43px;
                background-position: center;
                background-size: cover;
                margin: auto 0;
                margin-right: 16px;
                background-color: #e1e1e1;
            }

            .details {
                color: #000;
                font-family: Montserrat;
                width: 100%;
                h5 {
                    font-size: 16px;
                    font-weight: 700;
                    margin: 0;
                    margin-bottom: 2px;
                }

                p {
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    margin: 0;
                }
            }
        }

        .question {
            padding: 47px 0;
            padding-bottom: 31px;
            display: flex;
            color: #000;
            font-family: Montserrat;
            font-weight: 700;
            width: 100%;

            h1 {
                font-size: 45px !important;
                margin: auto 0;
                margin-left: 0;
            }

            h4 {
                font-size: 18px !important;
                margin: auto 0;
                margin-left: 10px;
            }
        }

        .content {
            display: unset;
            .text {
                color: #000;
                text-align: justify;
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 400;
                padding: 0 62px;
                padding-right: 5%;
                margin-top: 0;

                p {
                    overflow: hidden;
                }

                img, video {
                    max-width: 100%;
                    border-radius: 12px;
                }
            }

            &.response {
                display: flex;
                padding-left: 0;
                padding-bottom: 0;
                a {
                    color: #0070E0;
                    text-decoration: revert;
                }
                .check-container {
                    min-width: 30px;
                    min-height: 30px;
                    margin-right: 16px;
                    display: flex;
                    font-size: 30px;

                    i {
                        opacity: .3;
                        transition: .25s ease all;
                        cursor: pointer;
                        color: #595959;
                        &:hover {
                            opacity: 1;
                        }
                    }

                    &.approved {
                        i {
                            color: #00990F;
                            opacity: 1;
                        }
                    }
                   
                }
                p {
                    //padding-left: 0;
                    margin-top: 0;
                }

                .text {
                    padding: 0;
                }
            }
        }
    }

    .topic-body {
        @media only screen and (max-width: 768px) {
            .content {
                p {
                    padding: 0;
                }
            }
        }
    }

    .topic-options {
        display: flex;
        font-size: 13px;
        padding-top: 20px;
        justify-content: end;
        i, p {
            margin-right: 40px;
            cursor: pointer;
            opacity: .5;
            transition: .15s ease all;

            &:hover {
                opacity: 1;
            }

            &.delete {
                color: #FF1515;
            }
        }

        .like-btn {
            display: flex;
            cursor: pointer;
            &:hover {
                i {
                    transform: scale(1.15);
                }
            }
            i {
                font-size: 14px;
                margin: auto;
                margin-right: 5px;
                transition: .25s ease all;
                opacity: .85;
            }
            &.liked {
                color: rgb(255, 90, 90);
                i {
                    animation: pop 1.5s ease-in-out infinite;
                }
            }
            p {
                opacity: 1;
            }
            
        }
        
    }

    .add-topic, .add-response {
        .question-title {
            width: 100%;
            display: flex;
            padding: 22px 0;
            .input {
                border-radius: 50px;
                border: 1px solid #000;
                outline: none;
                padding: 18px 51px;
                width: 100%;
                color: #000;
                font-size: 15px;
                font-weight: 300;
                color: #000;
                transition: .25s ease all;

                &::placeholder {
                    opacity: .8;
                }
                &:focus {
                    border-color: rgb(106, 106, 249);
                }
            }
        }
        .ql-container {
            border-radius: 50px;
            border: 1px solid #000;
            margin-top: 22px;
            border-top: 1px solid #000 !important;
            min-height: 500px;
            max-height: 800px;
            overflow: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
            .ql-editor {
                padding: 40px 50px;
                font-family: Montserrat;
                font-size: 15px;
                font-weight: 300;
                color: #000;
                min-height: 500px;
                &.ql-blank {
                    &::before {
                        color: #949494;
                        padding-left: 35px;
                    }
                }
            }
        }

        .ql-toolbar {
            border: 0;
            .ql-formats {
                border-radius: 20.913px;
                background: #F1F1F1;
                padding: 10px 50px;
                margin-bottom: 15px;
            }
        }

        .section {
            padding-top: 35px;
            color: #000;
            font-family: Montserrat;
            font-size: 12.478px;
            font-weight: 400;
            width: 80%;
            margin: auto;
            text-transform: capitalize;

            @media only screen and (max-width: 480px) {
                width: 90%;    
            }

            .border {
                width: 80%;
                margin: 0 auto;
                height: 0.966px;
                max-width: 698px;
                background: #D9D9D9;
            }

            .input-container {
                .selector-container {
                    border-radius: 50px;
                    border: 1px solid #000;
                    width: 100%;
                    display: flex;
                    padding: 18px 51px;
                    justify-content: space-between;
                    cursor: pointer;
                    transition: .25s ease all;
                    position: relative;

                    .selected-type {
                        display: flex;
                        h5 {
                            margin: 0;
                            color: #000;
                            font-family: Montserrat;
                            font-size: 12.478px;
                            font-weight: 500;
                            margin-left: 10px;
                        }

                        i {
                            font-size: 15px;
                        }
                    }

                    div, i {
                        margin: auto 0;
                    }

                    i {
                        font-size: 25px;
                    }

                    &:hover {
                        background: rgba(90, 171, 241, 0.06);
                    }
                }
            }

            .btns-container {
                display: flex;
                justify-content: center;
                .btn {
                    font-size: 20px;
                    cursor: pointer;
                    transition: .5s ease all;
                    padding: 14px 30px;
                    border-radius: 26.5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    max-height: 52px;
                    height: 52px;

                    @media only screen and (max-width: 376px) {
                        font-size: 15px;
                        padding: 10px;
                    }

                    &.confirm {
                        background: #000;
                        color: #fff;
                    }

                    &.cancel {
                        margin-right: 10px;
                    }

                    &:hover {
                        background: #343434;
                        color: #fff;
                    }

                    &.disabled {
                        cursor: no-drop;
                        opacity: .5;
                    }
                }
            }
        }

        .forum-types {
            padding: 20px 50px;
            box-shadow: rgb(100 100 111 / 30%) 0px 7px 29px 0px;
            left: 0;
            transform: translateY(105%);

            @media only screen and (max-width: 768px) {
                padding: 20px;

                @media only screen and (max-width: 376px) {
                    padding: 20px 0;
                    width: 120%;
                    left: -10%;
                    transform: translateY(103%);
                }
            }

            .title {
                font-size: 12.48px;
                font-weight: 400;
                text-transform: capitalize;
                margin-bottom: 16px !important;
            }

            .elements {
                padding: 0;
                max-height: unset;
            }

            .element {
                display: flex;

                .icon {
                    width: 43px;
                    height: 43px;
                    min-width: 43px;
                    min-height: 43px;
                    display: flex;
                    margin-right: 11px !important;
                    i {
                        margin: auto;
                        color: #FFA500;
                        font-size: 19px;
                    }
                }

                .text-details {
                    margin: auto 0;
                    h5 {
                        color: #404D61;
                        font-weight: 600;
                        font-size: 14px;
                        margin: 0;
                    }

                    p {
                        color: #A0A0A0;
                        font-size: 11px;
                        font-weight: 400;
                        margin: 0;
                    }
                }
            }
        }

        @media only screen and (max-width: 480px) {
            .ql-container {
                border-radius: 0;
                border-right: 0;
                border-left: 0;
            }
        }

        .recaptcha {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 35px;
        }
    }

    .add-response {
        margin-top: 50px;
        .ql-container {
            min-height: unset;
            min-height: 250px;
            max-height: 800px;
            overflow: scroll;
            &::-webkit-scrollbar {
                display: none;
            }
            .ql-editor {
                min-height: unset;
            }
        }
    }

    .autocomplete-list {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        transform: translateY(100%);
        z-index: 1;
        animation: fadeIn .3s ease-in-out;
        padding: 27px 49px;
        border-radius: 42px;

        @media only screen and (max-width: 768px) {
            padding: 27px;

            .elements {
                padding: 0 20px !important;
            }
        }

        .title {
            margin-bottom: 30px;
            font-weight: 600;
        }

        .elements {
            max-height: 290.8px;
            overflow-y: scroll;
            padding: 0 28px;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        a,
        .element {
            padding-bottom: 15px;
            display: block;
            transition: .25s ease all;

            &:last-of-type {
            }
            &:hover {
                transform: scale(1.02);
            }
        }

        .border {
            width: 85%;
            margin: 0 auto;
            margin-bottom: 30px;
            height: 1px;
            background-color: #D9D9D9;

            &:last-of-type {
                margin-bottom: 11px;
            }
        }
    }
}

.status-bar {
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0px 8px 13px 0px rgba(0, 0, 0, 0.06);
    font-size: 16px;
    b {
        font-weight: 600;
    }
    &.deleted, &.alert {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }

    &.pinned {
        color: #004085;
        background-color: #cce5ff;
        border-color: #b8daff;
    }

    &.missing {
        margin-top: 2rem;
    }
}