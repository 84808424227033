.download-page-container {
    background-color: #fff;
    color: #000;
    font-family: Montserrat;
    min-height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
    padding-bottom: 200px;

    .center {
        margin-left: auto;
        margin-right: auto;
        width: 60%;
        position: relative;
        z-index: 4;

        @media only screen and (max-width: 1250px) {
            width: 70%;
        }

        @media only screen and (max-width: 500px) {
            width: auto;
            width: 95%;
        }
    }

    video {
        width: 100%;
        height: 70vh;
    }

    .title {
        font-weight: 700;
        font-size: 2rem;
        text-transform: capitalize;
        margin-top: 5rem;
        margin-bottom: 2rem;
    }

    .sub-title {
        font-weight: 400;
        font-size: 0.75rem;
        margin-top: -1.5rem;
    }

    .for-os {
        background-color: #f8f8f8;
        text-transform: capitalize;

        font-weight: 700;
        padding: 1.1rem 1.3rem;
        margin-top: 1rem;
    }

    .paragraph {
        font-size: 0.75rem;
        margin-top: 2rem;
    }

    .icon {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        min-width: 10px;
        min-height: 10px;
        display: block;

        &.download {
            background-image: url("../download-page/icons/download.svg");
        }

        &.arrow-down {
            background-image: url("../download-page/icons/arrow-down.svg");
        }

        &.arrow-up {
            background-image: url("../download-page/icons/arrow-up.svg");
        }
    }
}

.download-button-container {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 4rem;

    .download-button {
        cursor: pointer;
        background-color: #000;
        color: white;
        border-radius: 17px;
        display: flex;
        width: fit-content;
        padding: 0.6rem 2.5rem;
        align-items: center;
        gap: 8px;
    }

    .button {
        background-color: #000;
        color: white;
        font-size: 0.75rem;
        font-weight: 700;
    }

    i {
        height: 20px;
        width: 20px;
    }

    .download-comment {
        font-size: x-small;
        margin-left: 20px;
    }
}

.instructions-container {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    .step-container {
        display: flex;
        border-bottom: 1px solid #d9d9d9;
        min-height: max-content;

        .step-image,
        .step {
            flex: 1;
        }

        .step-image {
            width: 100%;
            margin: 2rem;
            min-height: 250px;

            @media only screen and (max-width: 768px) {
                margin: 1rem;
            }

            @media only screen and (max-width: 768px) {
                margin-left: 0;
            }
        }

        .step {
            padding: 2rem;
            display: flex;
            align-items: center;
            gap: 2rem;
            border-left: 1px solid #d9d9d9;
            width: 100%;
            max-width: 70%;

            @media only screen and (max-width: 1250px) {
                padding: 0.5rem;
                gap: 1rem;
            }

            @media only screen and (max-width: 500px) {
                gap: 1rem;
            }

            .number {
                font-size: 5.75rem;
                font-weight: 500;
                padding: 2rem;

                @media only screen and (max-width: 768px) {
                    font-size: 4rem;
                    padding: 1rem;
                }

                @media only screen and (max-width: 500px) {
                    font-size: 2rem;
                    padding: 0;
                }
            }

            .instruction-container {
                display: flex;

                flex-direction: column;

                .step-number {
                    font-size: 1.2rem;

                    @media only screen and (max-width: 500px) {
                        font-size: 1rem;
                    }
                }

                .instruction {
                    font-size: 15px;

                    @media only screen and (max-width: 500px) {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.faq-container {
    display: flex;
    flex-direction: column;
    gap: 55px;

    .accordion-item {
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        background-color: white;

        .accordion-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 1.25rem;
            cursor: pointer;

            &.show {
                border-bottom: 1px solid #d9d9d9;
            }

            .question {
                font-size: 18px;
                font-weight: 700;
            }

            .toggle-icon {
                .icon {
                    height: 2rem;
                    width: 1rem;
                }
            }
        }

        .accordion-body {
            padding: 0px;
            font-size: 0.75rem;
            height: 0px;
            transition: .25s ease all;
            overflow: hidden;
            opacity: 0;

            &.show {
                height: fit-content;
                padding: 30px;
                opacity: 1;
            }
        }
    }
}

.requirements-list {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    .requirements-item {
        display: flex;
        gap: 2px;
        align-items: center;

        .bullet-point {
            width: 2px;
            height: 2px;
            min-width: 2px;
            min-height: 2px;
            border-radius: 100%;
            background-color: #000;
            margin-right: 8px;
        }
    }
}

.ellipse {
    position: absolute;
    background-color: #eaeaea;

    @media only screen and (max-width: 500px) {
        display: none;
    }

    &.small {
        width: 280px;
        height: 280px;
        border-radius: 280px;
        left: -12%;
        top: 50%;
        transform: translate(-12%, -50%);

        @media only screen and (max-width: 1250px) {
            width: 180px;
            height: 180px;
        }

        @media only screen and (max-width: 768px) {
            transform: translate(-52%, -50%);
        }
    }

    &.medium {
        width: 300px;
        height: 300px;
        border-radius: 300px;
        left: -12%;
        top: 20%;
        transform: translate(-12%, -20%);

        @media only screen and (max-width: 1250px) {
            width: 200px;
            height: 200px;
        }

        @media only screen and (max-width: 768px) {
            width: 200px;
            height: 200px;
            transform: translate(-42%, -20%);
        }
    }

    &.large {
        width: 400px;
        height: 400px;
        border-radius: 499px;
        right: -10%;
        top: 30%;
        transform: translate(10%, 30%);

        @media only screen and (max-width: 1250px) {
            width: 300px;
            height: 300px;
        }

        @media only screen and (max-width: 768px) {
            transform: translate(50%, 30%);
        }
    }

    &.extra-large {
        width: 500px;
        height: 500px;
        border-radius: 638px;
        right: -16%;
        bottom: 0;
        transform: translate(16%, 0);

        @media only screen and (max-width: 1250px) {
            width: 400px;
            height: 400px;
        }

        @media only screen and (max-width: 768px) {
            transform: translate(56%, 0);
        }
    }
}

.epic-store {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin: auto;
    margin-top: 4rem;

    &:hover {
        .icon {
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 16px;
        }
    }

    .icon {
        width: 100px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 6px;
        transition: .5s ease all;
        border-radius: 10px;
    }

    .paragraph {
        margin: auto;
        width: 62px;
        font-size: 12px;
        text-align: center;
    }
}