.profile-page {
    background-color: #f3f4f8;
    min-height: 100vh;
    width: 100vw;
    color: #000;
    font-family: Montserrat;
    overflow: hidden;
    .header-container {
        position: fixed;
    }

    .profile-banner {
        width: 100vw;
        min-height: 282px;
        height: 25vh;
        // background-image: url(https://metaroids.com/wp-content/uploads/2023/02/Midjourney-How-to-Make-Amazing-AI-Images-that-Go-Viral.webp);
        background-color: #e1e1e1;
        background-position: center;
        background-size: cover;
        position: relative;
        &.settings {
            height: 46.4vh;
        }

        .options {
            width: 100%;
            height: 100%;
            position: absolute;
            background: #00000029;
            transition: .25s ease all;
            opacity: 0;
            display: flex;
            cursor: pointer;
            &:hover {
                opacity: 1;
            }

            i {
                color: #fff;
                font-size: 40px;
                margin: auto;
                opacity: .9;
            }
        }
    }

    .profile-info {
        display: flex;
        padding: 0 5.7vw 0 14vw;

        @media only screen and (max-width: 768px) {
            position: relative;
            padding: 0 5vw;

            .profile-titles {
                padding-left: 0 !important;
            }
        }

        @media only screen and (min-width: 425px) and (max-width: 650px) {
            .profile-picture-container {
                transform: translateY(-23%) !important;
    
                .profile-picture {
                    width: 100px !important;
                    height: 100px !important;
                }
            }
        }

        &.mobile {
            display: block;

            .profile-titles {
                padding-left: 0 !important;
                display: flex !important;

                &.mobile {
                    transform: translateY(-27%);
                }
            }

            .profile-picture-container {
                margin-right: 10px;
                margin-bottom: 5px;

                .profile-picture {
                    width: 100px !important;
                    height: 100px !important;
                }
            }
        }

        .profile-picture-container {
            transform: translateY(-34%);

            .options {
                width: 100%;
                height: 100%;
                position: absolute;
                background: #00000029;
                z-index: 1;
                border-radius: 50%;
                border: 6px solid white;
                transition: .25s ease all;
                opacity: 0;
                display: flex;
                cursor: pointer;
                &:hover {
                    opacity: 1;
                }

                i {
                    color: #fff;
                    font-size: 40px;
                    margin: auto;
                    opacity: .9;
                }
            }

            .profile-picture {
                border-radius: 50%;
                background-image: url(https://verseworldstorage.blob.core.windows.net/verseweb/VerseWorld/empty.jpg);
                background-color: #e1e1e1;
                background-position: center;
                background-size: cover;
                width: 124px;
                height: 124px;
                border: 6px solid #fff;
                position: relative;

                .verified-badge-container {
                    background-color: #fff;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    display: flex;
                    position: absolute;
                    right: -13px;
                    z-index: 1;
                    .verified-badge {
                        margin: auto;
                        width: 28px;
                        height: 28px;
                        background-image: url(../verified.svg);
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                }

            }
        }
    }

    .profile-titles {
        padding-left: 10px;

        h2 {
            font-size: 30px;
            font-weight: 700;
            margin: 11px 0;
            text-transform: capitalize;
            &.settings-titles {
                color: #232360;
                margin-left: 28px;
                margin-top: 25px;
            }
        }

        h4 {
            color: #494949;
            font-size: 12px;
            font-family: Montserrat;
            font-weight: 400;
            margin: 0;
        }
    }

    .collection {
        padding: 35px 75px 0 81px;

        &:last-of-type {
            padding-bottom: 50px;
        }

        .input-container-profile {
            color: #232360;
            font-size: 14px;
            font-weight: 500;
            width: 50%;
            margin-bottom: 25px;
            input[type="text"], 
            input[type="password"], 
            input[type="date"], 
            input[type="email"], 
            input[type="number"], textarea {
                border-radius: 9px;
                border: 1px solid #E5E5ED;
                background-color: #f3f4f8;
                color: #768396;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                padding: 13px 17px;
                outline: none;
                width: 100%;
                max-width: 250px;
                height: fit-content;
                &::placeholder {
                    opacity: .5;
                    font-weight: 400;
                }

                &:focus {
                    border-color: #428BFF;
                }

                &:disabled {
                    cursor: no-drop;
                    background-color: #eaeaed;
                }
            }

            .voucher-container {
                display: flex;
                gap: 10px;
                .btn {
                    margin: auto 0;
                    cursor: pointer;
                    border-radius: 8px;
                    border: 1px solid #D9DADE;
                    padding: 11px 14px;
                    color: #000;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 500;
                    height: fit-content;
                    transition: .25s ease all;

                    &.confirm, &:hover {
                        background: #000;
                        border-color: #000;
                        color: #FFFFFF;
                    }

                    &.confirm {
                        margin-left: 10px;
                        &:hover {
                            transform: scale(1.05);
                        }
                    }

                    &.disabled {
                        opacity: .5;
                        cursor: no-drop;

                        &:hover {
                            transform: scale(1);
                        }
                    }
                }
            }

            .status {
                font-size: 11px;
                i {
                    font-size: 15px;
                    transform: translateY(2px);
                }
                
                &.error {
                    color: #FF1515;
                }

                &.valid {
                    color: #00990F;
                }
            }
        }

        .titles-container {
            display: flex;
        }

        .title {
            color: #000;
            font-size: 30px;
            font-weight: 700;
            text-transform: capitalize;
            margin: auto;
            margin-left: 0;
        }

        .filters-container {
            display: flex;
        }

        .filters {
            display: flex;
            flex-wrap: wrap;
            padding: 0 37px;
            margin: auto;

            &.invisible {
                opacity: 0;
                user-select: none;
                pointer-events: none;
            }

            .checkbox-container {
                display: flex;
                cursor: pointer;
                margin: auto;
                margin-left: 0;
                margin-right: 20px;

                .checkbox {
                    margin: auto;
                    margin-right: 4px;
                    font-size: 40px;
                    transition: .25s ease all;
                    color: #D9D9D9;

                    &.checked {
                        color: #000000;
                    }
                }

                p {
                    margin: auto;
                    color: #000;
                    font-size: 12px;
                    font-family: Montserrat;
                    font-weight: 500;
                    text-transform: capitalize;
                }
            }
        }

        .search-container {
            display: flex;
            color: #000;
            font-size: 15px;
            font-family: Montserrat;
            font-weight: 400;
            margin: auto;
            margin-right: 0;
            display: none;
            input[type="text"] {
                border-radius: 21.284px;
                background-color: #FFF;
                border: 1px solid #fff;
                font-size: 11.743px;
                font-family: Poppins;
                font-weight: 400;
                line-height: 17.615px;
                text-align: left;
                box-shadow: rgb(100 100 111 / 6%) 0px 5px 10px 0px;
                max-width: 240px;
                height: fit-content;
                padding: 8px 25px;
                box-sizing: border-box;
                margin: auto;

                &:focus {
                    outline: transparent;
                }

                &::placeholder {
                    text-align: center;
                    color: rgba(0, 0, 0, 0.62);
                }
            }

            .pagination {
                margin: auto;
                margin-left: 34px;
            }
        }

        .gallery-container {
            display: flex;
            flex-wrap: wrap;
            padding-top: 6.5px;
            position: relative;
            min-height: 200px;
            
            .element {
                background-color: #e1e1e1;
                background-position: center;
                background-size: cover;
                border-radius: 11.517px;
                width: 133px;
                height: 171px;
                box-shadow: 0px -39px 14px 0px rgba(0, 0, 0, 0.25) inset;
                margin: 16.5px 10px;
                margin-left: 0;
                margin-bottom: 0;
                cursor: pointer;
                transition: .25s ease all;
                position: relative;
                animation: appear .25s ease-in-out;

                &:hover {
                    transform: scale(1.025);
                }

                .title-card {
                    color: #FFF;
                    font-size: 15px;
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 700;
                    margin: 0;
                    position: absolute;
                    bottom: 0;
                    text-align: center;
                    padding-bottom: 9px;
                    width: 100%;
                    text-transform: capitalize;

                    border-bottom-left-radius: 11.517px;
                    border-bottom-right-radius: 11.517px;
                    backdrop-filter: blur(10px);
                    padding-top: 9px;
                }

                .badge {
                    font-weight: 700;
                    font-size: 12.519px;
                    font-family: Poppins;
                    background-color: #FFA500;
                    border-radius: 15.5px;
                    color: #FFFFFF;
                    text-align: center;
                    width: fit-content;
                    padding: 6px 26px;
                    margin-left: auto;
                    margin-right: 0;
                    position: absolute;
                    top: 9px;
                    right: 6px;
                }
            }

            .navigate {
                background-color: #fff;
                border-radius: 50%;
                width: 40.2px;
                height: 40.2px;
                color: #000000;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                transition: .25s ease all;
                cursor: pointer;
                position: absolute;
                top: 45%;
                left: 0;
                z-index: 1;
                display: flex;
                transform: translateX(-50%);

                i {
                    margin: auto;
                    text-align: center;
                    font-size: 25px;
                }

                &:hover {
                    background-color: #000000;
                    color: #fff;
                }

                &.right {
                    right: 0;
                    left: unset;
                    transform: translateX(50%);
                }

                &.disabled {
                    opacity: .3;
                    cursor: no-drop;

                    &:hover {
                        background-color: #fff;
                        color: #000;
                    }
                }
            }
        }
    }


    @media only screen and (max-width: 900px) {
        .collection {
            padding: 30px 16px;

            @media only screen and (max-width: 425px) {
                padding-top: 0 !important;
            }

            .titles-container {
                display: unset;

                .filters-container {
                    display: flex;
                    -webkit-flex-flow: column-reverse;
                    flex-flow: column-reverse;

                    .search-container {
                        .pagination {
                            display: none;
                        }

                        input[type="text"] {
                            max-width: unset;
                            width: 100%;
                            margin-top: 19px;
                            margin-bottom: 27px;
                            box-shadow: rgb(100 100 111 / 20%) 0px 3px 15px 0px;
                        }
                        
                    }

                    .filters-scroll {
                        width: 100%;
                        max-width: 100%;
                        overflow-x: scroll;
                        padding-bottom: 20px;
                        padding-top: 20px;

                        .filters {
                            padding: 0;
                            flex-wrap: unset;
                            &::-webkit-scrollbar {
                                display: none;
                            }
                        }
                    }
                }
            }

            .gallery-container {
                .element {
                    width: 120.097px;
                    height: 153.869px;
                    border-radius: 10.378px;
                    box-shadow: 0px -48.631919860839844px 39.626007080078125px -13.508866310119629px rgba(0, 0, 0, 0.53) inset;
                    margin: auto;
                    margin-bottom: 16.5px;
                }
            }

        }
    }

    &.settings {
        .profile-info {
            justify-content: space-between;
            width: 100%;

            .first-part {
                display: flex;
            }

            .settings-btns-container {
                display: flex;
                width: fit-content;
                margin: 25px 0;
                .btn {
                    cursor: pointer;
                    border-radius: 8px;
                    border: 1px solid #D9DADE;
                    padding: 11px 14px;
                    color: #000;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 500;
                    height: fit-content;
                    transition: .25s ease all;

                    &.confirm, &:hover {
                        background: #000;
                        border-color: #000;
                        color: #FFFFFF;
                    }

                    &.confirm {
                        margin-left: 10px;
                        &:hover {
                            transform: scale(1.05);
                        }
                    }

                    &.disabled {
                        opacity: .5;
                        cursor: no-drop;

                        &:hover {
                            transform: scale(1);
                        }
                    }
                }
            }
        }

        .settings-navigator {
            display: flex;
            
            a {
                color: #6A7181;
                font-size: 14px;
                font-weight: 500;
                margin-right: 25px;
                transition: .25s ease all;
                &.active {
                    color: #232360;
                    font-weight: 700;
                }
            }
        }

        .content {
            padding: 0 5.7vw 0 14vw;

            @media only screen and (max-width: 768px) {
                padding: 0 5vw;
            }

            .info-settings {
                padding: 5vh 0;
                padding-left: 2px;
                .input-container {
                    color: #232360;
                    font-size: 14px;
                    font-weight: 500;
                    width: 31%;
                    margin-bottom: 25px;
                    input[type="text"], 
                    input[type="password"], 
                    input[type="date"], 
                    input[type="email"], 
                    input[type="number"], textarea {
                        border-radius: 9px;
                        border: 1px solid #E5E5ED;
                        background-color: #f3f4f8;
                        color: #768396;
                        font-family: Montserrat;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        padding: 13px 17px;
                        outline: none;
                        width: 100%;
                        &::placeholder {
                            opacity: .5;
                            font-weight: 400;
                        }

                        &:focus {
                            border-color: #428BFF;
                        }

                        &:disabled {
                            cursor: no-drop;
                            background-color: #eaeaed;
                        }
                    }

                    textarea {
                        resize: vertical;
                        min-height: 150px;
                        font-weight: 400;
                    }

                    label {
                        font-size: 12px;
                        font-weight: 400;
                        text-align: right;
                        display: block;
                        margin-top: 10px;
                    }

                    .email-container {
                        width: 100%;
                        position: relative;
                        i {
                            position: absolute;
                            top: 15px;
                            left: 16px;
                            font-size: 16px;
                        }

                        input[type="email"] {
                            padding-left: 45px;
                        }
                    }

                    &.bio {
                        width: 50%;
                    }
                }
                .part {
                    display: flex;
                    justify-content: space-between;
                    

                    .vertical {
                        width: 45%;

                        .input-container {
                            width: 100%;

                            &.small {
                                width: 40%;
                            }
                        }
                    }
                }

                .warning {
                    border-radius: 4px;
                    background: var(--ui-negative-light, #FDE8E6);
                    width: 100%;
                    padding: 14px;
                    color: #000;
                    display: flex;
                    .icon {
                        font-size: 25px;
                        color: #CC493C;
                        padding-right: 20px;
                    }

                    .text {
                        h3 {
                            color: #000;
                            font-family: Open Sans;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 22px;
                            margin: 0;
                            margin-bottom: 10px;
                        }

                        p {
                            margin: 0;
                            font-family: Open Sans;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22px;
                        }
                    }
                }

                .content {
                    padding: 19px;

                    .checkbox-container {
                        display: flex;
                        cursor: pointer;

                        i {
                            margin: auto 0;
                            cursor: pointer;
                            transition: .25s ease all;
                            color: #000;
                            font-size: 26px;

                            &:hover {
                                transform: scale(1.1);
                            }
                        }

                        p {
                            margin: auto 0;
                            margin-left: 15px;
                            font-family: Montserrat;
                            font-size: 12.343px;
                            font-weight: 400;
                        }
                    }

                    .delete-button {
                        border-radius: 8px;
                        background: #F66;
                        color: #FFF;
                        font-family: Montserrat;
                        font-size: 14px;
                        font-weight: 700;
                        padding: 11px 14px;
                        max-width: 166px;
                        display: flex;
                        cursor: pointer;

                        @media only screen and (max-width: 426px) {
                            margin-top: 15px;
                        }

                        p {
                            margin: auto;
                            text-align: center;
                        }
                    }

                    .wide {
                        width: 80%;

                        @media only screen and (max-width: 769px) {
                            width: 100%;
                        }

                        .delete-container {
                            display: flex;

                            @media only screen and (max-width: 426px) {
                                display: unset;
                            }
                        }

                        input[type="password"] {
                            width: 60%;
                            margin-right: 3%;

                            @media only screen and (max-width: 426px) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.gallery-msg {
    text-align: 'center';
    padding-top: 35px;
    height: 200px;
    display: flex;

    div {
        margin: auto;
    }
}

.phantom-sync {
    display: flex;
    font-size: 12px;
    color: #000;
    opacity: .5;
    cursor: pointer;
    transition: .25s ease all;
    
    &:hover {
        opacity: 1;
    }

    i {
        font-size: 16px;
        margin: auto 0;
        margin-right: 10px;
    }

    div {
        margin: auto 0;
    }
}