$padding-image-edit: 25px;
$ui-background: #191d29;
$ui-border-radius: 5px;

.image-edit-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 4;
    font-family: Montserrat;
    color: white;

    .image-edit {
        padding: $padding-image-edit;
        background: $ui-background;
        border-radius: $ui-border-radius;
        filter: drop-shadow(0 0 11px rgba(0, 0, 0, .5));
        width: 60%;
        opacity: 1;
        max-width: 500px;
        margin: auto;
        position: relative;
        opacity: .97;
        animation: appear .25s ease-in-out;

        .cross {
            position: absolute;
            top: $padding-image-edit;
            right: $padding-image-edit;
            opacity: .8;
            cursor: pointer;
            transition: .2s ease all;

            &:hover {
                opacity: 1;
            }
        }

        p {
            font-size: 14px;
            padding-bottom: 8px;
            font-weight: 700;
        }
    }

    .input-container {
        display: flex;
        &.buttons {
            justify-content: space-evenly;
            margin-top: 10px;
            .cancel {
                background: -webkit-gradient(linear, left top, right bottom, from(#828489), to(#878c9b)) !important;
            }
        }
    }

    input[type="text"], .input-src {
        border: 2px solid hsla(0, 0%, 100%, .2) !important;
        outline: none !important;
        background: transparent !important;
        font-size: 14px !important;
        width: 70% !important;
        border-radius: 5px !important;
        padding: 3px 6px !important;
        color: white !important;
        margin-right: 15px !important;
        color: hsla(0, 0%, 100%, .8) !important;
        height: unset !important;
        &:focus {
            border: 2px solid hsla(0, 0%, 100%, .5) !important;
        }
    }

    .button {
        padding: 7.5px 0;
        background: -webkit-gradient(linear, left top, right bottom, from(#3053e4), to(#5c79f1));
        color: #fff;
        border-radius: 2px;
        font-size: 10px;
        text-align: center;
        opacity: .9;
        cursor: pointer;
        display: flex;
        width: 30%;
        max-width: 180px;
        opacity: .9;
        padding: 0;
        height: 35px;
        &:hover {
            opacity: 1;
        }

        div {
            display: flex;
            margin: auto;

            p {
                padding: 0;
                font-size: 13px;
            }
        }

        i {
            margin: auto;
            font-size: 15px;
            margin-right: 6px;
        }
    }
}

.publish-menu {
    width: 100%;
    padding: 0 10%;
    display: inline-flex;
    justify-content: end;
    z-index: 1;
    //position: absolute;
    padding-right: 0;
    right: 5vw;
    padding-left: 0;

    .button {
        padding: 7.5px 0;
        background: -webkit-gradient(linear, left top, right bottom, from(#3053e4), to(#5c79f1));
        color: #fff;
        border-radius: 2px;
        font-size: 10px;
        text-align: center;
        opacity: .9;
        cursor: pointer;
        display: flex;
        width: 30%;
        max-width: 180px;
        opacity: .9;
        height: 38.5px;
        width: 150px;
        font-weight: 700;
        margin: auto;
        margin-right: 0;
        margin-left: 15px;

        &.cancel {
            background: -webkit-gradient(linear, left top, right bottom, from(#191d29), to(#242834));
        }

        &.disabled {
            opacity: .5;
            cursor: no-drop;

            &:hover {
                opacity: .5;
            }
        }

        &:hover {
            opacity: 1;
        }

        div {
            display: flex;
            margin: auto;

            p {
                padding: 0;
                font-size: 13px;
                margin: 0;
            }
        }

        i {
            margin: auto;
            font-size: 15px;
            margin-right: 6px;
        }
    }
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}