body {
    background: rgb(44, 44, 44);
    overflow-x: hidden;
    min-height: 100vh;
    display: flex;
}

#root {
    margin: auto;
}

.login-container {
    width: 100vw;
    min-height: 100vh;

    /*width: 1440px;
    height: 900px;*/
    display: flex;
    margin: auto;
    background-color: white;
    position: relative;

    .panel {
        padding: 37px 60px;
        padding-right: 0;
        width: 50%;

        @media only screen and (max-width: 768px) {
            display: none;
        }

        .image {
            background-color: #e1e1e1;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            border-radius: 29px;
            position: relative;

            .titles-container {
                color: #fff;
                width: 100%;
                position: absolute;
                bottom: 0;
                padding-left: 8%;
                padding-bottom: 5%;
                font-family: 'Poppins';
                box-sizing: border-box;

                h2,
                p {
                    margin: 0;
                    font-weight: 700;
                    font-size: 37px;
                }

                p {
                    font-weight: 300;
                    font-size: 20px;
                }
            }
        }
    }

    .content {
        margin: auto;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 17px;
        width: 50%;
        max-height: 90vh;
        overflow-y: auto;

        @media only screen and (max-width: 768px) {
            padding-top: 110px;
            width: 80%;
        }

        @media only screen and (max-width: 426px) {
            width: 90%;
        }

        .logo {
            width: 70px;
            position: absolute;
            top: 20px;
            right: 3%;

            @media only screen and (max-width: 768px) {
                right: unset;
                left: 8%;
            }
        }

        .login {
            max-width: 70%;
            margin: auto;

            @media only screen and (max-width: 768px) {
                max-width: unset;
            }

            .switcher {
                background: #f5f5f5;
                border-radius: 33px;
                display: flex;
                padding: 9px 13px;
                justify-content: space-around;
                max-width: 329px;
                margin: auto;
                margin-bottom: 52px;

                div,
                a {
                    width: 45%;
                    text-align: center;
                    padding: 10px 0;
                    cursor: pointer;
                    transition: 0.1s ease all;
                    border-radius: 33px;
                    text-decoration: none;
                    color: #000;

                    &:hover {
                        background-color: #e1e1e1;
                        transition: 0.1s ease all;
                    }

                    &.active {
                        background-color: black;
                        color: #fff;
                        transition: 0.5s ease all;
                    }
                }
            }
        }

        h4 {
            text-align: center;
            font-weight: 400;
        }

        h3 {
            text-align: center;
            font-weight: 700;
            font-size: 25px;
        }

        .text-explainer {
            color: #5b5b5b;
            margin-bottom: 42px;

            b {
                font-weight: 600;
            }
        }

        .form {
            &.register {
                padding-bottom: 25px;
            }

            .input-container {
                position: relative;

                label {
                    display: block;
                    margin-bottom: 16px;
                    font-weight: 400;
                    color: #000;
                }

                input[type="text"],
                input[type="password"],
                input[type="email"],
                input[type="date"],
                input[type="number"] {
                    width: 100%;
                    border-radius: 40px;
                    border: 1px solid #000000;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 19.5px;
                    padding: 18px 31px;
                    margin-bottom: 30px;
                    color: #000;
                    transition: 0.1s ease;
                    box-sizing: border-box;

                    &.error-required {
                        border-color: rgb(255, 95, 95);
                    }

                    &:focus {
                        outline-color: #428bff;
                    }

                    &::placeholder {
                        color: #acacac;
                        text-transform: capitalize;
                        font-weight: 300;
                        font-size: 15px;
                    }
                }

                input[type="password"] {}

                span {
                    color: red;
                    font-size: 12px;
                    position: absolute;
                    bottom: 8%;
                    left: 5%;
                }

                .password-container {
                    position: relative;

                    input {
                        padding-right: 3.5rem;
                    }

                    i {
                        position: absolute;
                        right: 30px;
                        font-size: 25px;
                        cursor: pointer;
                        opacity: 0.8;
                        transition: 0.25s ease opacity;
                        transform: translateY(60%);

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }

            .forgot-password-container {
                display: flex;
                justify-content: space-between;
                font-size: 13px;
                margin-bottom: 65px;

                .remember-me {
                    display: flex;
                    cursor: pointer;
                    margin: auto;
                    margin-left: 0;

                    .checkbox {
                        margin: auto;
                        margin-right: 10px;
                        font-size: 25px;
                        transition: 0.25s ease all;
                        color: #5b5b5b;

                        &.checked {
                            color: #428bff;
                        }
                    }

                    p {
                        margin: auto;
                    }
                }

                .forgot-password {
                    margin: auto;
                    margin-right: 0;
                }
            }

            .login-btn-container {
                display: flex;
                justify-content: right;
            }

            .login-btn {
                text-align: center;
                padding: 10px 0;
                cursor: pointer;
                border-radius: 33px;
                background-color: black;
                color: #fff;
                transition: 0.25s ease all;
                width: fit-content;
                padding: 13px 95px;
                font-family: "Poppins";
                font-weight: 400;
                font-size: 16px;
                margin-bottom: 25px;

                &:hover {
                    background-color: rgb(62, 61, 61);
                }

                &.loading {
                    opacity: 0.5;
                    cursor: no-drop !important;
                }

                @media only screen and (max-width: 768px) {
                    margin: auto;
                    margin-bottom: 25px;
                }
            }
        }

        .invalid-input {
            outline-color: red !important;
        }
    }

    .otp-container {
        margin: auto;
        margin-bottom: 30px;
        width: fit-content;

        input[type="text"] {
            width: unset !important;
            max-width: 54px;
            padding: 20px 5px;
            border-radius: 10px;
            border: 1px solid #000000;
            font-size: 15px;
            font-weight: 400;
            outline: none;

            &:focus {
                border-color: #428BFF;
            }
        }

        .text-explainer {
            font-size: 14px;
            color: #428BFF;

            &.otp {
                margin: auto;
                margin-top: 8px;
                text-align: center;
                display: block;
            }

            &.resend {
                width: 200px;
                color: #000;
            }

            &.error {
                color: rgb(255, 95, 95);
            }
        }

        label {
            color: #000;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 10px;
            display: block;
        }
    }
}